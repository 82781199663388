import React from 'react';
import { Space } from 'antd';

import gettextCatalog from '@/react/services/I18nService';
import { ColumnType } from '@/react/shared/components/cd-table';
import { TagInterface } from '@/react/people/tag-list/types/tag.types';
import { OrderType } from '@/react/shared/models/table';
import { CdUsers, CdTags } from '@/react/shared/components/Icons';
import { getStateUrl } from '@/react/services/StateServiceFactory';

export const tagListColumn = () => {
  const columns: ColumnType<TagInterface>[] = [
    {
      dataIndex: 'name',
      key: 'name',
      title: gettextCatalog.getString('Name'),
      orderType: OrderType.STRING,
      render: (value, record) => {
        const filterCriteria = [
          {
            type: 'list',
            property: 'tags',
            operator: 'eq',
            value: record.id,
          },
        ];
        return (
          <Space>
            <CdTags type="secondary" />
            <a
              key={record.id}
              href={getStateUrl(`app.private.people.contacts.list`, {
                filter: btoa(JSON.stringify(filterCriteria)),
              })}
            >
              {value}
            </a>
          </Space>
        );
      },
    },
    {
      dataIndex: 'references',
      key: 'references',
      title: gettextCatalog.getString('Added to'),
      orderType: OrderType.NUMBER,
      render: (value) => (
        <Space>
          <CdUsers type="secondary" />
          {gettextCatalog.getPlural(
            value,
            '1 contact',
            '{{ $count | number }} contacts',
            { $count: value }
          )}
        </Space>
      ),
    },
    {
      dataIndex: 'lists',
      key: 'lists',
      render: (_, record) =>
        !record?.lists?.length &&
        gettextCatalog.getString('Not used for any lists'),
    },
  ];
  return columns;
};
