import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { concat, find, isEmpty, isNumber, map, reduce } from 'lodash';
import { DataNode } from 'antd/lib/tree';
import { TreeSelect, Space } from 'antd';

import { gettextCatalog } from '../../services/I18nService';

import { roleSelectors } from '@/react/organization/store/roleSlice';
import { useFetchRoles } from '@/react/organization/screens/manage-roles/hooks';

const getRoleFilterTreeData = (hasMultiChurchNewRoles: boolean): DataNode[] => {
  const legacyRoles = [
    {
      title: gettextCatalog.getString('Organisation administrator'),
      key: '8',
      value: '8',
    },
    {
      title: gettextCatalog.getString('Calendar'),
      key: '0-2',
      value: '0-2',
      children: [
        {
          title: gettextCatalog.getString('Calendar access'),
          key: '15',
          value: '15',
        },
        {
          title: gettextCatalog.getString(
            'Calendar share events with all users'
          ),
          key: '16',
          value: '16',
        },
        {
          title: gettextCatalog.getString('Can edit all events'),
          key: '17',
          value: '17',
        },
        {
          title: gettextCatalog.getString('Can edit group events'),
          key: '4',
          value: '4',
        },
        {
          title: gettextCatalog.getString('Can view absences'),
          key: '11',
          value: '11',
        },
        {
          title: gettextCatalog.getString('Can manage absences'),
          key: '10',
          value: '10',
        },
        {
          title: gettextCatalog.getString('Can access sensitive information'),
          key: '9',
          value: '9',
        },
      ],
    },
    {
      title: gettextCatalog.getString('People'),
      key: '0-3',
      value: '0-3',
      children: [
        {
          title: gettextCatalog.getString('People access'),
          key: '2',
          value: '2',
        },
        {
          title: gettextCatalog.getString('Can access sensitive information'),
          key: '20',
          value: '20',
        },
      ],
    },
    {
      title: gettextCatalog.getString('Forms'),
      key: '0-4',
      value: '0-4',
      children: [
        {
          title: gettextCatalog.getString('Forms access'),
          key: '18',
          value: '18',
        },
        {
          title: gettextCatalog.getString('Can access sensitive information'),
          key: '19',
          value: '19',
        },
      ],
    },
    {
      title: gettextCatalog.getString('Payments and Giving access'),
      key: '14',
      value: '14',
    },
    {
      title: gettextCatalog.getString('Website'),
      key: '3',
      value: '3',
    },
  ];
  const treeData = [];
  if (hasMultiChurchNewRoles) {
    treeData.push({
      title: gettextCatalog.getString('All roles'),
      key: 'allRoles',
      value: 'allRoles',
      children: [],
    });
    treeData.push({
      title: gettextCatalog.getString('Legacy roles'),
      key: 'legacyRoles',
      value: 'legacyRoles',
      children: legacyRoles,
    });
  } else {
    return legacyRoles;
  }
  return treeData;
};

export const UserRoleFilterComponent = ({
  filterHandler,
  hasMultiChurchNewRoles,
}: {
  filterHandler: (roles) => void;
  hasMultiChurchNewRoles: boolean;
}) => {
  const fetchRoles = useFetchRoles();
  const [treeData, setTreeData] = useState([]);
  const churchRoles = useSelector(roleSelectors.selectChurchRoles);
  const organizationRoles = useSelector(roleSelectors.selectOrganizationRoles);

  const onRoleFilterChange = (selectedKeys) => {
    const selectedRoleFilters = reduce(
      selectedKeys,
      (accumulator, selectedKey) => {
        if (!isNaN(selectedKey) && isNumber(parseInt(selectedKey))) {
          accumulator.roles.push(parseInt(selectedKey));
        } else if (selectedKey.length > 10) {
          accumulator.roles.push(selectedKey);
        }
        return accumulator;
      },
      { roles: [] }
    );
    filterHandler([...selectedRoleFilters.roles]);
  };

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newTreeData = getRoleFilterTreeData(hasMultiChurchNewRoles);
    const newRoles = concat(churchRoles, organizationRoles);
    if (!isEmpty(newRoles) && find(newTreeData, { key: 'allRoles' })) {
      find(newTreeData, { key: 'allRoles' }).children = map(
        newRoles,
        (role) => ({
          title: role.name,
          key: role.id,
          value: role.id,
        })
      );
    }
    setTreeData(newTreeData);
  }, [churchRoles, organizationRoles, hasMultiChurchNewRoles]);

  return (
    <Space>
      {/* <Text>{gettextCatalog.getString('Filter by:')}</Text> */}
      <TreeSelect
        treeData={treeData}
        treeCheckable
        onChange={onRoleFilterChange}
        allowClear
        showSearch={false}
        style={{ minWidth: 200, textAlign: 'left' }}
        dropdownStyle={{ whiteSpace: 'nowrap', minWidth: 350 }}
        placeholder={
          hasMultiChurchNewRoles
            ? gettextCatalog.getString('Filter by roles')
            : gettextCatalog.getString('Filter by permissions')
        }
        maxTagCount={0}
        maxTagPlaceholder={(selectedItems) => {
          const count = selectedItems.length;
          if (count > 1) {
            return `${count} ` + hasMultiChurchNewRoles
              ? gettextCatalog.getString('roles')
              : gettextCatalog.getString('permissions');
          } else {
            return `${count} ` + hasMultiChurchNewRoles
              ? gettextCatalog.getString('role')
              : gettextCatalog.getString('permission');
          }
        }}
      />
    </Space>
  );
};
