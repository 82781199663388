import { mainApi } from '../../api';
import { WeekdayName } from '../../calendar/availabilities/hooks/use-booking-options';
import { Visibility } from '../../calendar/models/calendar';
import { CategoryType } from '../../shared/models/category';

class TaxonomiesService {
  public loadData: () => Promise<Taxonomy[]> = async () => {
    const response = await mainApi.get(`/taxonomies`);
    if (!response.ok) {
      throw { code: response.status, error: response.originalError };
    }
    return response.data as Taxonomy[];
  };
}

export default new TaxonomiesService();

export interface Taxonomy<T = CategoryType> {
  id: number;
  name: string;
  description?: string;
  color: number | null;
  type: T;
  registerAttendance: boolean;
  declinePrimaryIntentions: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface AvailabilityTaxonomy
  extends Taxonomy<CategoryType.availability> {
  alias: string;
  bookingOptions: AvailabilityBookingOption[];
  config: {
    publicName: string;
    isBookable: boolean;
    event: {
      title: string;
      categoryIds: number[];
      churchIds: number[];
      visibility: Visibility;
    };
    form: {
      id: string;
      alias: string;
    };
    duration: { amount: number; unit: string };
    minimumNoticePeriod: { amount: number; unit: string };
    startTimeIncrement: { amount: number; unit: string };
  };
  groups: [
    {
      id: number;
      name: string;
    },
  ];
}

export interface AvailabilityBookingOption {
  id: string;
  name: string;
  description?: string;
  config?: {
    bookingHours?: Record<WeekdayName, { start: string; end: string }[]>;
    mainResourceId?: number;
  };
  taxonomyId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}
