import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { CreateNewContactWithBasicInformationModal } from './CreateNewContactWithBasicInformation';
import { showEditBasicInformationModal } from './EditBasicInformation';

import {
  CdEditIcon,
  CdPlus,
  CHURCHDESK_COLOR,
} from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';
import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';

const AvatarStyled = styled(CdUserAvatar)`
  position: absolute;
  top:0px;
  left: 0px;
  background-color: ${CHURCHDESK_COLOR};
  opacity: 0;
  transition: 0.5s all ease-in-out;
`;
const Container = styled.div`
cursor: pointer;
 position: relative;
&:hover .edit {
    opacity: 0.8;
  }
`;
export const EditBasicInformationButton = ({
  person,
  createMode,
  allowCreate,
  executeFromPicture,
}) => {
  const openModal = () => {
    if (createMode) {
      return CreateNewContactWithBasicInformationModal({
        createMode,
      });
    } else {
      showEditBasicInformationModal({ contactId: person.id });
    }
  };
  if (createMode) {
    return allowCreate ? (
      <Button icon={<CdPlus />} onClick={openModal}>
        {gettextCatalog.getString('New contact')}
      </Button>
    ) : null;
  }
  if (executeFromPicture) {
    return (
      <Container>
        <CdUserAvatar
          picture={person?.picture.url || '/img/user-default.png'}
          size={88}
          light={true}
        />
        <AvatarStyled
          size={88}
          onClick={
            (person.fields.firstName.canEdit ||
              person.fields.picture.canEdit) &&
            openModal
          }
          customIcon={<CdEditIcon size="2xs" />}
          className="edit"
          genericAvatar={true}
        />
      </Container>
    );
  }
  return person.fields.firstName.canEdit || person.fields.picture.canEdit ? (
    <Button type="link" onClick={openModal}>
      <CdEditIcon size="lg" />
    </Button>
  ) : null;
};
