import { useRecoilCallback } from 'recoil';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { formsApi } from '@/react/api';
import { useEventRegistrations } from '@/react/calendar/store/events/event';
import NotificationService from '@/react/services/NotificationService';
import { gettextCatalog } from '@/react/services/I18nService';
import { handleError } from '@/react/services/ErrorHandlingService';
import { EventForm } from '@/react/calendar/types/event';
import { getStateUrl } from '@/react/services/StateServiceFactory';

export const useSignUp = (calendarId?: number, form?: EventForm) => {
  const [initialForm] = useState(form);
  const queryClient = useQueryClient();
  // eslint-disable-next-line destructuring/no-rename
  const { data: attendees } = useEventRegistrations({
    extraData: { calendarId },
  });
  const formHasBeenChanged = initialForm !== form;

  const addAttendee = () => {
    window.open(form.link, '_blank');
  };

  const editAttendee = (submissionId: string) => {
    const url = getStateUrl('app.private.forms.editResponse', {
      formId: form.id,
      responseId: submissionId,
    });

    window.open(url, '_blank');
  };

  const refreshAttendees = useCallback(
    () =>
      queryClient.invalidateQueries({ queryKey: ['useEventRegistrations'] }),
    [queryClient]
  );

  const resendTickets = useRecoilCallback(
    () => async (submissionId: string) => {
      const response = await formsApi.post<any>(`/forms/tickets/resend`, {
        formId: form.id,
        submissionId,
      });
      if (!response.ok) {
        handleError(response);
        throw response;
      } else {
        NotificationService.notifySuccess(
          gettextCatalog.getString('Tickets are being resent.')
        );
        return response.data;
      }
    },
    [form]
  );

  const deleteAttendee = useRecoilCallback(
    () => async (submissionId: string) => {
      const response = await formsApi.delete<any>(
        `/forms/${form.id}/submissions/${submissionId}`
      );
      if (!response.ok) {
        handleError(response);
      } else {
        NotificationService.notifySuccess(
          gettextCatalog.getString('Participant deleted!')
        );
        refreshAttendees();
      }
    },
    [refreshAttendees, form]
  );

  useEffect(() => {
    refreshAttendees();
  }, [form, refreshAttendees]);

  return {
    attendees: attendees.items,
    hasTickets: attendees.hasTickets,
    hasMoreThanOnePeopleSection: attendees.hasMoreThanOnePeopleSection,
    formHasBeenChanged,
    addAttendee,
    editAttendee,
    refreshAttendees,
    resendTickets,
    deleteAttendee,
  };
};
