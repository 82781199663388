import { useCallback } from 'react';
import { Form } from 'antd';

import { TagInterface } from '@/react/people/types/tag.type';
import { useCreateOrEditTag } from '@/react/people/tag-list/store/tags';

export const useCreateTag = ({
  data,
  isEdit,
}: { data: TagInterface; isEdit: boolean }) => {
  const [createTagForm] = Form.useForm();
  const { createOrEditTag } = useCreateOrEditTag();
  const onCreateOrEditTag = useCallback(async () => {
    await createTagForm.validateFields().then(async (value) => {
      const names = value.names.split(',');
      names.map((name: string) => {
        createOrEditTag({ name, data, isEdit });
      });
    });
  }, [createTagForm, data, isEdit, createOrEditTag]);

  return {
    createTagForm,
    onCreateOrEditTag,
  };
};
