import React, { useEffect, useState } from 'react';
import { Button, Form, Space, Typography } from 'antd';

import { ManagementContactType } from './ManageContactsDrawer';
import TriStateCheckBox from './TriStateCheckBox';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdCogs, CdNewTemplate } from '@/react/shared/components/Icons';
import { navigate } from '@/react/services/StateServiceFactory';
import { openCreateOneTagModal } from '@/react/people/components/CreateTag';

const { Title } = Typography;
const ManageContactsDrawerForm = ({
  list,
  icon,
  label,
  noDataLabel,
  type,
  modal,
  url,
  param,
  doesItemBelongToAllPeople,
  doesItemBelongToSomePeople,
  form,
  setFormInitialValue,
  formInitialValue,
  setDisabled,
}) => {
  const [initial, setInitial] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<number>();
  useEffect(() => {
    if (list.length > 0 && !initial) {
      setFormInitialValue(
        Object.entries(form.getFieldValue('list')).map(([Id, value]) => ({
          id: +Id,
          value: value,
        }))
      );
      setInitial(true);
    }
  }, [form, list, setFormInitialValue, initial]);

  useEffect(() => {
    if (newTag) {
      setFormInitialValue((prev) => [...prev, { id: newTag, value: false }]);
    }
  }, [newTag, setFormInitialValue]);

  const whatIsCheckbox = (itemId, type) => {
    if (doesItemBelongToAllPeople(itemId, type)) return 'checked';
    if (doesItemBelongToSomePeople(itemId, type)) return 'indeterminate';
    return false;
  };

  return (
    <div style={{ marginBottom: '24px' }}>
      <Form
        style={{ display: 'flex', flexDirection: 'column' }}
        form={form}
        onValuesChange={() => {
          const list = form.getFieldsValue(true).list;
          const formValues = Object.entries(list).map(([Id, value]) => ({
            id: +Id,
            value: value,
          }));

          const hasFormChanged = formValues.every(
            (item, index) => item.value === formInitialValue[index].value
          );
          if (hasFormChanged) {
            setDisabled(true);
          } else {
            setDisabled(false);
          }
        }}
      >
        <Title
          level={3}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Space>
            {icon}
            {label}
          </Space>
          <Space>
            {type === ManagementContactType.Tag && (
              <Button
                size="small"
                onClick={() => {
                  openCreateOneTagModal({ setNewTag });
                }}
              >
                <Space>
                  <CdNewTemplate fixedWidth />
                  {gettextCatalog.getString('Create')}
                </Space>
              </Button>
            )}
            <Button
              size="small"
              onClick={() => {
                navigate(url, param);
                modal.hide();
              }}
            >
              <CdCogs fixedWidth />
            </Button>
          </Space>
        </Title>

        {list?.length > 0 ? (
          list.map((item) => (
            <Form.Item
              preserve={true}
              name={['list', `${item.id}`]}
              style={{ marginBottom: '0' }}
              initialValue={whatIsCheckbox(item.id, type)}
              key={item.id}
            >
              <TriStateCheckBox>{item.name}</TriStateCheckBox>
            </Form.Item>
          ))
        ) : (
          <Space
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '8px',
            }}
          >
            {icon}
            {noDataLabel}
          </Space>
        )}
      </Form>
    </div>
  );
};

export default ManageContactsDrawerForm;
