import React, { useCallback } from 'react';
import { Button, Col, Dropdown, Row, Space, Tag, Typography } from 'antd';
import { get, isEmpty } from 'lodash';
import { map, size, sortBy, truncate } from 'lodash';
import moment from 'moment';

import {
  useRemoveUserFromCurrentOrganization,
  useResendUserEmailInvite,
  useUpdateUserStatus,
} from '../store/users';

import { showSendUserEmailModal } from './SendUserEmailModal';
import { showUserPermissionsModal } from './UserPermissionsModal';

import { CdUserAvatar } from '@/react/user/components/cd-user-avatar/CdUserAvatar';
import { ColumnType } from '@/react/shared/components/cd-table';
import { OrderType } from '@/react/shared/models/table';
import { sortBoolean } from '@/react/shared/sorter';
import { User } from '@/react/user/types/User.types';
import { navigate } from '@/react/services/StateServiceFactory';
import { organizationStatus } from '@/react/user/screens/UserList';
import { showConfirmInputModal } from '@/react/shared/components/cd-confirm-input-modal/CdConfirmInputModal';
import { showUserTimeRegistrationModal } from '@/react/user/components/UserTimeRegistrationModal';
import { showTimeRegistrationLandingModal } from '@/react/user/components/TimeRegistrationLandingModal';
import {
  CdEditIcon,
  CdUserLock,
  UserTimeRegistrationEnabled,
  CdMessageLegacy,
  CdUndo,
  CdUsersClock,
  CdCheckIcon,
  CdBan,
  CdUserTime,
  CdAngleDown,
} from '@/react/shared/components/Icons';
import AuthorizationService from '@/react/services/AuthorizationService';
import { gettextCatalog } from '@/react/services/I18nService';
import { useOrganization } from '@/react/organization/hooks/useOrganization';
import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import { sortString } from '@/react/shared/libs/sorter';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';

const getConfirmModalTitle = (user) => {
  switch (user.organizationStatus) {
    case organizationStatus.ACTIVE:
      return gettextCatalog.getString('Block user?');
    case organizationStatus.BLOCKED:
      return gettextCatalog.getString('Unblock user?');
    case organizationStatus.CREATED_WITHOUT_LOGIN:
      return gettextCatalog.getString('Activate user?');
    default:
      return '';
  }
};

const getConfirmModalOkText = (user) => {
  switch (user.organizationStatus) {
    case organizationStatus.ACTIVE:
      return gettextCatalog.getString('Block');
    case organizationStatus.BLOCKED:
      return gettextCatalog.getString('Unblock');
    case organizationStatus.CREATED_WITHOUT_LOGIN:
      return gettextCatalog.getString('Activate');
    default:
      return '';
  }
};

const { Link, Text } = Typography;

export const GetUserTableColumns = () => {
  const { organizationSettings } = useOrganization();
  const showChurchSelector = get(window, 'cdApp.showChurchSelector');
  const hasUserAdminRole =
    AuthorizationService.hasPermission('canAdministerUsers');

  const { mutateUserStatus } = useUpdateUserStatus();
  const { resendUserEmailInvite } = useResendUserEmailInvite();
  const { handleRemoveUserFromCurrentOrganization } =
    useRemoveUserFromCurrentOrganization();
  const toggleStatusModal = useCallback(
    (user) =>
      showConfirmModal({
        title: getConfirmModalTitle(user),
        message: (
          <Space direction="vertical">
            {user.organizationStatus === organizationStatus.BLOCKED &&
              gettextCatalog.getString(
                'Are you sure you want to unblock {{ user }}?',
                {
                  user: user.name || user.email,
                }
              )}
            {user.organizationStatus === organizationStatus.ACTIVE &&
              gettextCatalog.getString(
                'Are you sure you want to block {{ user }} from the system?',
                {
                  user: user.name || user.email,
                }
              )}
            {user.organizationStatus ===
              organizationStatus.CREATED_WITHOUT_LOGIN &&
              gettextCatalog.getString(
                'Are you sure you want to activate {{ user }} ?',
                {
                  user: user.name || user.email,
                }
              )}
            {user.organizationStatus === organizationStatus.ACTIVE && (
              <Space direction="vertical">
                {gettextCatalog.getString(
                  'Blocking this user will result in the following:'
                )}

                <ul style={{ margin: 0 }}>
                  <li>
                    {gettextCatalog.getString(
                      'They will no longer be able to log in.',
                      undefined,
                      'User blocked will result in'
                    )}
                  </li>
                  <li>
                    {gettextCatalog.getString(
                      'They will lose access to all protected content.',
                      undefined,
                      'User blocked will result in'
                    )}
                  </li>
                  <li>
                    {gettextCatalog.getString(
                      'All permissions will be revoked, and they will be removed from all groups.',
                      undefined,
                      'User blocked will result in'
                    )}
                  </li>
                  <li>
                    {gettextCatalog.getString(
                      'Their absence will be hidden from other users.',
                      undefined,
                      'User blocked will result in'
                    )}
                  </li>
                </ul>
              </Space>
            )}
            {user.organizationStatus === organizationStatus.BLOCKED &&
              gettextCatalog.getString(
                'When {{ user }} was blocked, all permissions and group memberships were removed. Everything will need to be manually added again.',
                {
                  user: user.name || user.email,
                }
              )}
            {user.organizationStatus === organizationStatus.ACTIVE &&
              gettextCatalog.getString(
                'Note: If the user is unblocked, permissions and group memberships will need to be manually restored.',
                undefined,
                'Shown when a user is about to get blocked'
              )}
          </Space>
        ),
        onOk: async () => {
          // On activating a createdWithoutLogin user, should send an email and change the status to active
          user.organizationStatus ===
            organizationStatus.CREATED_WITHOUT_LOGIN &&
            resendUserEmailInvite({
              userId: user.id,
              userStatus: user.organizationStatus,
            });
          mutateUserStatus({
            userId: user.id,
            userStatus: !user.status ? 1 : 0,
          });
        },
        okText: getConfirmModalOkText(user),
        okButtonProps: {
          danger: user.organizationStatus === organizationStatus.ACTIVE,
        },
      }),
    [mutateUserStatus, resendUserEmailInvite]
  );

  const columns: ColumnType<User>[] = [
    {
      title: gettextCatalog.getString('Name'),
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (text, record) => (
        <Space>
          <CdUserAvatar picture={record?.picture} />
          <Space size={0} direction="vertical">
            <a
              onClick={() =>
                navigate('app.private.settings.users.detail', {
                  id: record.id,
                })
              }
            >
              {truncate(text || record.email, { length: 30 })}
            </a>
            <div style={{ fontSize: '12px' }}>
              <Link style={{ color: 'black' }} href={`mailto:${record.email}`}>
                {truncate(record.email, { length: 35 })}
              </Link>
            </div>
          </Space>
        </Space>
      ),
    },
    {
      title: gettextCatalog.getString('Status'),
      dataIndex: 'lastAccess',
      key: 'lastAccess',
      width: 200,
      orderType: OrderType.DATE,
      render: (text, record) => {
        const statusLabel = (
          <Space>
            {record.organizationStatus === organizationStatus.ACTIVE &&
              gettextCatalog.getString('Active')}
            {record.organizationStatus === organizationStatus.BLOCKED &&
              gettextCatalog.getString('Blocked')}
            {record.organizationStatus ===
              organizationStatus.CREATED_WITHOUT_LOGIN &&
              gettextCatalog.getString('Created')}
          </Space>
        );
        return (
          <>
            {hasUserAdminRole ? (
              <a onClick={() => toggleStatusModal(record)}>{statusLabel}</a>
            ) : (
              statusLabel
            )}
            {record.organizationStatus === organizationStatus.ACTIVE ||
            record.organizationStatus ===
              organizationStatus.CREATED_WITHOUT_LOGIN ? (
              <small style={{ display: 'block' }}>
                {text ? (
                  moment(text).fromNow()
                ) : record.editUser ? (
                  <CdVerticalSpace>
                    {gettextCatalog.getString('Never logged in')}
                    <Button
                      size="small"
                      style={{ marginTop: 5 }}
                      onClick={() =>
                        resendUserEmailInvite({
                          userId: record.id,
                          userStatus: record.organizationStatus,
                        })
                      }
                    >
                      <Space>
                        <CdUndo type="secondary" />
                        <Typography.Text style={{ fontSize: '12px' }}>
                          {record.organizationStatus ===
                            organizationStatus.ACTIVE &&
                            gettextCatalog.getString('Invite again')}
                          {record.organizationStatus ===
                            organizationStatus.CREATED_WITHOUT_LOGIN &&
                            gettextCatalog.getString('Invite')}
                        </Typography.Text>
                      </Space>
                    </Button>
                  </CdVerticalSpace>
                ) : (
                  gettextCatalog.getString('Never logged in')
                )}
              </small>
            ) : null}
          </>
        );
      },
    },
    {
      title: gettextCatalog.getString('Roles'),
      key: 'permissions',
      render: (text, record) => (
        <>
          {record.organizationStatus === organizationStatus.ACTIVE &&
            (size(record.churchRoles) > 0 ||
            size(record.organizationRoles) > 0 ? (
              <Row wrap={false}>
                <Col flex="auto">
                  <Space wrap size="small">
                    {map(
                      record.organizationRoles.concat(
                        record.churchRoles.sort((a, b) =>
                          sortString(a.name, b.name)
                        )
                      ),
                      (role) => (
                        <Tag key={`tag-${role.id}`} style={{ marginRight: 0 }}>
                          {role.name}
                        </Tag>
                      )
                    )}
                  </Space>
                </Col>
              </Row>
            ) : (
              <Row wrap={false}>
                <Col flex="auto">
                  {gettextCatalog.getString('0 permissions')}
                </Col>
              </Row>
            ))}
          {(record.organizationStatus === organizationStatus.BLOCKED ||
            record.organizationStatus ===
              organizationStatus.CREATED_WITHOUT_LOGIN) && (
            <Text>{gettextCatalog.getString('None')}</Text>
          )}
        </>
      ),
    },
    {
      title: gettextCatalog.getString('Parishes'),
      key: 'churches',
      hidden: !showChurchSelector,
      render: (text, record) => (
        <>
          <Row wrap={false}>
            <Col flex="auto">
              <Space wrap size="small">
                {map(sortBy(record.churches, 'name'), (church) => (
                  <Tag key={church.id} style={{ marginRight: 0 }}>
                    {church.name}
                  </Tag>
                ))}
                {isEmpty(record.churches) ? (
                  <Text>{gettextCatalog.getString('None')}</Text>
                ) : null}
              </Space>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: gettextCatalog.getString('Time registration'),
      key: 'timeRegistrationEnabled',
      dataIndex: 'timeRegistrationEnabled',
      align: 'center',
      sorter: (a, b) =>
        sortBoolean(a.timeRegistrationEnabled, b.timeRegistrationEnabled),
      orderType: OrderType.NUMBER,
      render: (data) => {
        if (data) {
          return <UserTimeRegistrationEnabled size="lg" />;
        }
      },
    },
    {
      title: '',
      key: 'action',
      width: '100px',
      align: 'right',
      render: (text, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 0,
                label: (
                  <Space>
                    <CdEditIcon />
                    {gettextCatalog.getString('Edit')}
                  </Space>
                ),
                onClick: () =>
                  navigate('app.private.settings.users.edit', {
                    id: record.id,
                  }),
                disabled: !record.editUser,
              },
              {
                key: 1,
                label: (
                  <Space>
                    <CdUserLock />
                    {gettextCatalog.getString('Edit permissions')}
                  </Space>
                ),
                onClick: () =>
                  showUserPermissionsModal({
                    userId: record.id,
                    userName: record.name,
                    userEmail: record.email,
                  }),
                disabled: !record.editUser,
              },
              {
                key: 3,
                label: (
                  <Space>
                    <CdMessageLegacy />
                    {gettextCatalog.getString('Send E-Mail')}
                  </Space>
                ),
                onClick: () =>
                  showSendUserEmailModal({
                    userId: record.id,
                    userName: record.name,
                    userEmail: record.email,
                  }),
              },
              !record.lastAccess &&
                record.editUser &&
                record.organizationStatus === organizationStatus.ACTIVE && {
                  key: 4,
                  label: (
                    <Space>
                      <CdUndo />
                      {gettextCatalog.getString('Invite again')}
                    </Space>
                  ),
                  onClick: () =>
                    resendUserEmailInvite({
                      userId: record.id,
                      userStatus: record.organizationStatus,
                    }),
                },
              hasUserAdminRole && {
                key: 5,
                label: (
                  <Space>
                    <CdUsersClock />
                    {gettextCatalog.getString('Manage time registration')}
                  </Space>
                ),
                onClick:
                  organizationSettings.timeRegistrationCap === 0
                    ? () => showTimeRegistrationLandingModal()
                    : () =>
                        showUserTimeRegistrationModal({
                          currentUserId: record.id,
                          userName: record.name,
                          timeRegistrationEnabled:
                            record.timeRegistrationEnabled,
                          initialTimeBalance: record.initialTimeBalance,
                          userEmail: record.email,
                        }),
              },
              { type: 'divider' },
              {
                key: 2,
                label: (
                  <Space>
                    {record.organizationStatus ===
                      organizationStatus.BLOCKED && (
                      <Space>
                        <CdCheckIcon />
                        {gettextCatalog.getString('Unblock')}
                      </Space>
                    )}

                    {record.organizationStatus ===
                      organizationStatus.ACTIVE && (
                      <Space>
                        <CdBan />
                        {gettextCatalog.getString('Block')}
                      </Space>
                    )}
                    {/* for created without login once the status is changed to activate, BE sends an email to the user */}
                    {record.organizationStatus ===
                      organizationStatus.CREATED_WITHOUT_LOGIN && (
                      <Space>
                        <CdUndo />
                        {gettextCatalog.getString('Activate')}
                      </Space>
                    )}
                  </Space>
                ),
                onClick: () => toggleStatusModal(record),
                disabled: !record.editUser,
              },
              record.organizationStatus === organizationStatus.BLOCKED && {
                key: 6,
                label: (
                  <Space>
                    <CdUserTime />
                    {gettextCatalog.getString('Remove user')}
                  </Space>
                ),
                onClick: () =>
                  showConfirmInputModal({
                    title: gettextCatalog.getString('Remove user?'),
                    confirmType: 'text',
                    confirmLabel: gettextCatalog.getString(
                      "Enter the user's name ({{ name}})",
                      {
                        name: record.name || record.email,
                      }
                    ),
                    confirmWord: record.name || record.email,
                    okText: gettextCatalog.getString('Remove'),
                    message: (
                      <Space direction="vertical">
                        {gettextCatalog.getString(
                          'Are you sure you want to remove {{ name }}? This action cannot be undone.',
                          {
                            name: record.name || record.email,
                          }
                        )}
                        {gettextCatalog.getString(
                          "The following user's data will be deleted. Remember to export the data before removing the user."
                        )}
                        <ul style={{ margin: 0 }}>
                          <li>
                            {gettextCatalog.getString(
                              'Absence reports',
                              null,
                              'Will be deleted when user is removed'
                            )}
                          </li>
                          {AuthorizationService.hasPackage('safeguarding') && (
                            <li>
                              {gettextCatalog.getString(
                                'Safeguarding reports',
                                null,
                                'Will be deleted when user is removed'
                              )}
                            </li>
                          )}
                          {organizationSettings.timeRegistrationCap > 0 && (
                            <li>
                              {gettextCatalog.getString(
                                'Time registration reports',
                                null,
                                'Mentioned in a list of data that will be deleted when the user is removed.'
                              )}
                            </li>
                          )}
                          <li>
                            {gettextCatalog.getString(
                              'Event bookings and rotas',
                              null,
                              'Mentioned in a list of data that will be deleted when the user is removed.'
                            )}
                          </li>
                        </ul>
                      </Space>
                    ),
                    okButtonProps: {
                      danger: true,
                    },
                    onOk: () =>
                      handleRemoveUserFromCurrentOrganization(record.id),
                  }),
                disabled: !record.editUser,
              },
            ],
          }}
          key={`user-actions-${record.id}`}
          placement="bottomRight"
          trigger={['click']}
          overlayStyle={{ minWidth: 200 }}
        >
          <Button>
            <Space>
              {gettextCatalog.getString('Actions')}
              <CdAngleDown />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];
  return columns;
};
