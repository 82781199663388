import { useCallback, useEffect } from 'react';
import { Form, Input } from 'antd';
import React from 'react';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import gettextCatalog from '@/react/services/I18nService';
import { useCreateOneTag } from '@/react/people/tag-list/store/tags';

export const openCreateOneTagModal = createCdModal<{ setNewTag: any }>(
  ({ setNewTag, setModalProps }) => {
    const [form] = Form.useForm();
    const { mutateOneTag } = useCreateOneTag();
    const onCreateTag = useCallback(async () => {
      await form.validateFields().then(async (value) => {
        mutateOneTag({ name: value.name, setNewTag });
      });
    }, [form, mutateOneTag, setNewTag]);

    useEffect(() => {
      setModalProps({
        modalType: ModalType.MODAL,
        title: gettextCatalog.getString('Create tag'),
        okText: gettextCatalog.getString('Create'),
        onOk: onCreateTag,
      });
    }, [onCreateTag, setModalProps]);
    return (
      <Form style={{ display: 'flex', flexDirection: 'column' }} form={form}>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: gettextCatalog.getString('Please input a name.'),
            },
          ]}
        >
          <Input placeholder={gettextCatalog.getString('Name your tag')} />
        </Form.Item>
      </Form>
    );
  }
);
