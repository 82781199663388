import React from 'react';
import { Alert, Form, Spin, Typography } from 'antd';

import { useDeleteCategories } from '../../hooks/useCategories';

import { createCdModal } from '@/react/shared/components/cd-modal-new/CdModal2';
import { gettextCatalog } from '@/react/services/I18nService';
import { CdCheckbox } from '@/react/shared/components/cd-checkbox/CdCheckbox';
import { CdSelect } from '@/react/shared/components/cd-select/CdSelect';
import { CdWarningIcon } from '@/react/shared/components/Icons';

interface DeleteCategoryModalProps {
  type: string;
  taxonomyId?: number;
}
export const DeleteCategoryModal = createCdModal<DeleteCategoryModalProps>(
  ({ setModalProps, type, taxonomyId }) => {
    const {
      form,
      initialValues,
      isLoading,
      shouldTransfer,
      options,
      taxonomyName,
    } = useDeleteCategories({
      setModalProps,
      type,
      taxonomyId,
    });

    return (
      <Spin spinning={isLoading}>
        <Form layout="vertical" form={form} initialValues={initialValues}>
          <Typography.Text>
            {gettextCatalog.getString(
              'Are you sure you want to delete "{{name}}"? This action cannot be undone.',
              {
                name: taxonomyName,
              }
            )}
          </Typography.Text>
          {options?.length > 0 && (
            <Form.Item
              name="shouldTransfer"
              valuePropName="checked"
              style={{ marginTop: '16px' }}
            >
              <CdCheckbox>{getTransferMessage(type)}</CdCheckbox>
            </Form.Item>
          )}
          {shouldTransfer && options?.length ? (
            <Form.Item name="replacementId">
              <CdSelect
                placeholder={gettextCatalog.getString('Choose a category')}
                options={options}
              />
            </Form.Item>
          ) : (
            <Alert
              message={getWarningMessage(type, taxonomyName)}
              type="warning"
              icon={<CdWarningIcon />}
            />
          )}
        </Form>
      </Spin>
    );
  }
);

const getTransferMessage = (type: string) => {
  switch (type) {
    case 'event':
      return gettextCatalog.getString(
        'Transfer all events to another category'
      );
    case 'blog':
      return gettextCatalog.getString('Transfer all blogs to another category');
    case 'absence':
      return gettextCatalog.getString(
        'Transfer all absences to another category'
      );
  }
};
const getWarningMessage = (type: string, name: string) => {
  switch (type) {
    case 'event':
      return gettextCatalog.getString(
        'Warning! All events belonging to {{name}} will be lost.',
        { name }
      );
    case 'blog':
      return gettextCatalog.getString(
        'Warning! All blogs belonging to {{name}} will be lost.',
        { name }
      );
    case 'absence':
      return gettextCatalog.getString(
        'Warning! All absences belonging to {{name}} will be lost.',
        { name }
      );
  }
};
