import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import gettextCatalog from '@/react/services/I18nService';
import { useDeleteTag } from '@/react/people/tag-list/store/tags';
import { UserHasPrivilege } from '@/react/user/store/permissions';

export const useTagList = () => {
  const [searchTag, setSearchTag] = useState<string>('');

  const handleSearch = (value: string) => {
    setSearchTag(value);
  };
  const canManageTags = useRecoilValue(
    UserHasPrivilege('organization.actions.managePeopleTags')
  );
  const canDeleteTags = useRecoilValue(
    UserHasPrivilege('organization.actions.deletePeopleTags')
  );
  const canCreateList = useRecoilValue(
    UserHasPrivilege('organization.people_segment.edit')
  );
  const { deleteTag, isPending } = useDeleteTag();
  const onDeleteTag = useCallback(
    (tagId) => {
      showConfirmModal({
        title: gettextCatalog.getString('Delete tag'),
        message: gettextCatalog.getString(
          'Deleting a tag doesn’t delete the tagged contacts but you’ll no longer be able to search for all contacts with this tag.'
        ),
        okText: gettextCatalog.getString('Delete'),
        okButtonProps: { danger: true },
        onOk: () => deleteTag(tagId),
      });
    },
    [deleteTag]
  );
  return {
    searchTag,
    handleSearch,
    onDeleteTag,
    canManageTags,
    canCreateList,
    canDeleteTags,
    isDeletePending: isPending,
  };
};
