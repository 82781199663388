import React, { useEffect } from 'react';
import { Typography } from 'antd';

import {
  createCdModal,
  ModalType,
} from '@/react/shared/components/cd-modal-new/CdModal2';
import { CreateShareableLinkModalProps } from '@/react/calendar/rota-scheme/types/rotaScheme.types';
import { CdLink } from '@/react/shared/components/Icons';
import gettextCatalog from '@/react/services/I18nService';
import CdrEmptyState from '@/react/shared/components/cd-result/CdrEmptyState';
import CopyInput from '@/react/contributions/components/details/CopyInput';

export const openCopyShareableLinkModal =
  createCdModal<CreateShareableLinkModalProps>(
    ({ title, url, setModalProps }) => {
      useEffect(() => {
        setModalProps({
          modalType: ModalType.POPUP,
          width: 600,
          title: gettextCatalog.getString('Get shareable link'),
          cancelText: gettextCatalog.getString('Close'),
          hideOk: true,
        });
      }, [setModalProps]);
      return (
        <CdrEmptyState
          title={title || gettextCatalog.getString('Get shareable link')}
          EmptyStateIcon={<CdLink />}
          subtitle={
            <Typography.Text type="secondary">
              {gettextCatalog.getString(
                'Share the link below on your website, newsletters and social media.'
              )}
            </Typography.Text>
          }
          buttonAction={<CopyInput value={url} />}
        />
      );
    }
  );
