import React, { createContext, useEffect } from 'react';
import {
  Divider,
  Form,
  Input,
  FormInstance,
  Typography,
  DatePicker,
  Select,
  Spin,
} from 'antd';
import styled from 'styled-components';

import { ConflictingPeopleRelationComponent } from '../ConflictingPeopleRelationComponent';
import {
  ConflictingPersonWithRelation,
  ContactInformationType,
} from '../../services/people.service';
import { CdVerticalSpace } from '../../../shared/components/cd-vertical-space/CdVerticalSpace';
import { ProfileAccess } from '../../types/people';

import { PhoneInput } from './PhoneInput';
import { NationalIdentificationNumberInput } from './NationalIdentificationNumberInput';
import { FormItemWithAccess } from './ItemWithAccess';

import { gettextCatalog } from '@/react/services/I18nService';
import { CdCountrySelect } from '@/react/shared/components/cd-country-select/CdCountrySelect';
const CustomForm = styled(Form)`
.ant-divider-horizontal{
  margin-top:8px;
  margin-bottom:16px;
  }
`;
export const FormItem = styled(Form.Item)`
  margin-bottom: 8px;
  `;
const ConfilictingPeoplePart = styled(Form.Item)`

`;
export const ProfileAccessContext = createContext<{
  profileAccess: ProfileAccess;
}>(null);

export const EditPersonalInformationForm = ({
  contactId,
  form,
  accessFields,
  personRelations,
  initialValues,
  isPending,
  isRefetching,
  setConflictingPeopleByEmail,
  setConflictingPeopleByPhone,
  conflictingPeopleByEmail,
  conflictingPeopleByPhone,
  civilStatusOptions,
  allergyOptions,
  dietaryOptions,
  denominationOptions,
  genderOptions,
}: {
  contactId: number;
  form: FormInstance;
  accessFields: any;
  personRelations: any;
  initialValues: any;
  isPending: boolean;
  isRefetching: boolean;
  setConflictingPeopleByEmail: (
    selectedValues: ConflictingPersonWithRelation[]
  ) => void;
  setConflictingPeopleByPhone: (
    selectedValues: ConflictingPersonWithRelation[]
  ) => void;
  conflictingPeopleByEmail: ConflictingPersonWithRelation[];
  conflictingPeopleByPhone: ConflictingPersonWithRelation[];
  civilStatusOptions: { label: string; value: string }[];
  allergyOptions: { label: string; value: string }[];
  dietaryOptions: { label: string; value: string }[];
  denominationOptions: { label: string; value: string }[];
  genderOptions: { label: string; value: string }[];
}) => {
  useEffect(() => {
    form.setFieldsValue(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPending, isRefetching, initialValues]);
  const labels = {
    email: gettextCatalog.getString('Email'),
    phone: gettextCatalog.getString('Mobile phone'),
    homePhone: gettextCatalog.getString('Home phone'),
    workPhone: gettextCatalog.getString('Work phone'),
  };
  const setConflictingPeopleByEmailSelectedValues = (
    selectedValues: ConflictingPersonWithRelation[]
  ): void => {
    setConflictingPeopleByEmail(selectedValues);
  };

  const setConflictingPeopleByPhoneSelectedValues = (
    selectedValues: ConflictingPersonWithRelation[]
  ): void => {
    setConflictingPeopleByPhone(selectedValues);
  };
  return (
    <Spin spinning={isPending}>
      <ProfileAccessContext.Provider value={{ profileAccess: accessFields }}>
        <CustomForm
          form={form}
          colon={false}
          labelCol={{ span: 6 }}
          labelAlign="right"
        >
          <Typography.Title level={5}>
            {gettextCatalog.getString('Contact')}
          </Typography.Title>
          <Divider />
          {accessFields?.email?.canEdit ? (
            <CdVerticalSpace>
              <FormItem
                name="email"
                label={labels.email}
                rules={[{ type: 'email' }]}
              >
                <Input />
              </FormItem>
              <ConfilictingPeoplePart
                label={' '}
                shouldUpdate
                noStyle={conflictingPeopleByEmail.length === 0}
              >
                {({ getFieldValue }) => (
                  <ConflictingPeopleRelationComponent
                    emailValue={getFieldValue('email')}
                    phoneValue={
                      getFieldValue('phone')?.phone &&
                      `+${
                        getFieldValue('phone').code +
                        getFieldValue('phone').phone
                      }`
                    }
                    valueType={ContactInformationType.EMAIL}
                    shouldListSingleConflictingPeople={false}
                    omittingPersonId={contactId}
                    existingRelations={personRelations}
                    setSelectedValues={
                      setConflictingPeopleByEmailSelectedValues
                    }
                  />
                )}
              </ConfilictingPeoplePart>
            </CdVerticalSpace>
          ) : (
            <AccessErrorMessage label={labels.email} />
          )}
          {accessFields?.phone?.canEdit ? (
            <CdVerticalSpace>
              <PhoneInput name="phone" label={labels.phone} />
              <Form.Item
                label=" "
                shouldUpdate
                noStyle={conflictingPeopleByPhone.length === 0}
              >
                {({ getFieldValue }) => (
                  <ConflictingPeopleRelationComponent
                    emailValue={getFieldValue('email')}
                    phoneValue={
                      getFieldValue('phone')?.phone &&
                      `+${
                        getFieldValue('phone').code +
                        getFieldValue('phone').phone
                      }`
                    }
                    valueType={ContactInformationType.PHONE}
                    shouldListSingleConflictingPeople={true}
                    omittingPersonId={contactId}
                    existingRelations={personRelations}
                    setSelectedValues={
                      setConflictingPeopleByPhoneSelectedValues
                    }
                  />
                )}
              </Form.Item>
            </CdVerticalSpace>
          ) : (
            <AccessErrorMessage label={labels.phone} />
          )}
          {accessFields?.homePhone?.canEdit ? (
            <PhoneInput name="homePhone" label={labels.homePhone} />
          ) : (
            <AccessErrorMessage label={labels.homePhone} />
          )}
          {accessFields?.workPhone?.canEdit ? (
            <PhoneInput name="workPhone" label={labels.workPhone} />
          ) : (
            <AccessErrorMessage label={labels.workPhone} />
          )}
          <Typography.Title level={5}>
            {gettextCatalog.getString('Address')}
          </Typography.Title>
          <Divider />

          <FormItemWithAccess
            name="street"
            fieldAccessName="street"
            label={gettextCatalog.getString('Street address')}
          >
            <Input />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="city"
            fieldAccessName="city"
            label={gettextCatalog.getString('City')}
          >
            <Input />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="zipcode"
            fieldAccessName="zipcode"
            label={gettextCatalog.getString('Postal code')}
          >
            <Input />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="country"
            fieldAccessName="country"
            label={gettextCatalog.getString('Country')}
          >
            <CdCountrySelect
              placeholder={gettextCatalog.getString('Country')}
              allowClear={false}
            />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="poBoxNumber"
            fieldAccessName="poBoxNumber"
            label={gettextCatalog.getString('P.O Box number')}
          >
            <Input />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="poBoxPostCode"
            fieldAccessName="poBoxPostCode"
            label={gettextCatalog.getString('P.O Box postal code')}
          >
            <Input />
          </FormItemWithAccess>
          <Typography.Title level={5}>
            {gettextCatalog.getString('Other Details')}
          </Typography.Title>
          <Divider />
          <FormItemWithAccess
            name="gender"
            fieldAccessName="gender"
            label={gettextCatalog.getString('Gender')}
          >
            <Select options={genderOptions} />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="occupation"
            fieldAccessName="occupation"
            label={gettextCatalog.getString('Occupation')}
          >
            <Input />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="note"
            fieldAccessName="note"
            label={gettextCatalog.getString('Internal note')}
            style={{ marginBottom: '14px' }}
          >
            <Input.TextArea />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="civilStatus"
            fieldAccessName="civilStatus"
            label={gettextCatalog.getString('Civil status')}
          >
            <Select options={civilStatusOptions} />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="denomination"
            fieldAccessName="denomination"
            label={gettextCatalog.getString('Denomination')}
          >
            <Select options={denominationOptions} />
          </FormItemWithAccess>
          <Typography.Title level={5}>
            {gettextCatalog.getString('Life events')}
          </Typography.Title>
          <Divider />
          <FormItemWithAccess
            name="birthday"
            fieldAccessName="birthday"
            label={gettextCatalog.getString('Birthday')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat('L')}
            />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="baptismDate"
            fieldAccessName="baptismDate"
            label={gettextCatalog.getString('Date of baptism')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat('L')}
            />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="confirmationDate"
            fieldAccessName="confirmationDate"
            label={gettextCatalog.getString('Date of confirmation')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat('L')}
            />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="civilMarriageDate"
            fieldAccessName="civilMarriageDate"
            label={gettextCatalog.getString('Date of wedding')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat('L')}
            />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="membershipDate"
            fieldAccessName="membershipDate"
            label={gettextCatalog.getString('Date of parish membership')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat('L')}
            />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="dateOfDeath"
            fieldAccessName="dateOfDeath"
            label={gettextCatalog.getString('Date of death')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format={gettextCatalog.getLongDateFormat('L')}
            />
          </FormItemWithAccess>
          <Typography.Title level={5}>
            {gettextCatalog.getString('Health')}
          </Typography.Title>
          <Divider />
          <FormItemWithAccess
            name="nationalIdentificationNumber"
            fieldAccessName="nationalIdentificationNumber"
            label={gettextCatalog.getString('National identification number')}
          >
            <NationalIdentificationNumberInput />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="allergy"
            fieldAccessName="allergy"
            label={gettextCatalog.getString('Allergies and intolerances')}
          >
            <Select mode="multiple" options={allergyOptions} />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="dietary"
            fieldAccessName="dietary"
            label={gettextCatalog.getString('Diet')}
          >
            <Select options={dietaryOptions} />
          </FormItemWithAccess>
          <FormItemWithAccess
            name="medicalNote"
            fieldAccessName="medicalNote"
            label={gettextCatalog.getString('Medical note')}
          >
            <Input.TextArea />
          </FormItemWithAccess>
        </CustomForm>
      </ProfileAccessContext.Provider>
    </Spin>
  );
};

export const AccessErrorMessage = ({ label }) => (
  <FormItem label={label}>
    <i>
      {gettextCatalog.getString(
        'You do not have the permission to edit sensitive fields.'
      )}
    </i>
  </FormItem>
);
