import { Button, Form, Input } from 'antd';
import React, { Suspense, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { CalendarPosterPrintDropDown } from './calendar-posters/CalendarPosterPrintDropDown';

import { UnlayerEditor } from '@/react/people/message-editor/advanced-editor/UnlayerEditor';
import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { CdSave } from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';
import { CdNotFound } from '@/react/shared/components/cd-result/CdNotFound';
import {
  useCalendarPoster,
  useEditCalendarPoster,
} from '@/react/calendar/hooks/event/useCalendarPoster';
import { CalendarPoster as ICalendarPoster } from '@/react/calendar/types/event';
import { handleAntFormValidationErrors } from '@/react/services/ErrorHandlingService';
import { EventQuery } from '@/react/calendar/store/events/event';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';

const CalendarPosterPage = ({
  $stateParams: { calendarId, posterId },
}: {
  $stateParams: { calendarId: number; posterId: number };
}) => {
  const [form] = Form.useForm<ICalendarPoster>();
  const event = useRecoilValue(EventQuery({ calendarId, clientVersion: 3 }));
  const { attachFile } = useCalendarPoster(posterId);
  const { updatePoster, poster } = useEditCalendarPoster({
    calendarId,
    posterId,
  });

  useEffect(() => {
    form?.setFieldsValue(poster);
  }, [poster, form]);

  const save = () => {
    form
      .validateFields()
      .then(() => {
        updatePoster({ poster: form.getFieldsValue(true) });
      })
      .catch(handleAntFormValidationErrors);
  };

  if (!poster || !event) {
    return (
      <CdNotFound
        title={gettextCatalog.getString('Not found')}
        subTitle={gettextCatalog.getString('The poster could not be found')}
      />
    );
  }

  return (
    <CdPage
      pageHeaderProps={{
        title: gettextCatalog.getString('Poster: {{ eventTitle }}', {
          eventTitle: event.title,
        }),
        subTitle: gettextCatalog.getString('By {{ author }}', {
          author: poster.author?.fullName,
        }),
        extra: [
          <CalendarPosterPrintDropDown
            buttonSize="middle"
            exportToken={poster.exportToken}
            calendarId={calendarId}
            key="print-button"
          />,
          <Button icon={<CdSave />} key="1" type="primary" onClick={save}>
            {gettextCatalog.getString('Save')}
          </Button>,
        ],
      }}
    >
      <Form form={form} style={{ flexGrow: 1, display: 'flex' }}>
        <Form.Item hidden={true} name="rendered">
          <Input />
        </Form.Item>
        <Form.Item name="content" noStyle>
          <UnlayerEditor
            form={form}
            attachFile={attachFile}
            enableVideoPreview={false}
            displayMode="web"
          />
        </Form.Item>
      </Form>
    </CdPage>
  );
};

export const CalendarPoster = (props) => (
  <Suspense fallback={<CdrPageLoader />}>
    <CalendarPosterPage {...props} />
  </Suspense>
);
