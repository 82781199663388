import React, { useCallback } from 'react';

import { CategoriesTable } from './CategoriesTable';

import { CdTabs } from '@/react/shared/components/cd-tabs/CdTabs';
import { gettextCatalog } from '@/react/services/I18nService';
import { CdAbsence, CdBlog, CdCalendar } from '@/react/shared/components/Icons';

export const CategoriesTabs = ({ activeKey, onTabChange }) => {
  const getTabs = useCallback(
    () => [
      {
        key: 'event',
        title: gettextCatalog.getString('Event categories'),
        subtitle: null,
        icon: <CdCalendar />,
        content: <CategoriesTable type="event" />,
      },
      {
        key: 'blog',
        title: gettextCatalog.getString('Blog categories'),
        subtitle: null,
        icon: <CdBlog />,
        content: <CategoriesTable type="blog" />,
      },
      {
        key: 'absence',
        title: gettextCatalog.getString('Absence types'),
        subtitle: null,
        icon: <CdAbsence />,
        content: <CategoriesTable type="absence" />,
      },
    ],
    []
  );
  return (
    <CdTabs
      items={getTabs()}
      activeKey={activeKey}
      onChange={onTabChange}
      fluidHeight
    />
  );
};
