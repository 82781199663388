import React from 'react';
import { Button, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

import { gettextCatalog } from '../../services/I18nService';
import { showCreateUserModal } from '../components/CreateUserModal';

import { UserList } from './UserList';

import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import AuthorizationService from '@/react/services/AuthorizationService';

export const SettingsUserListPage = () => {
  const hasUserAdminRole =
    AuthorizationService.hasPermission('canAdministerUsers');

  return (
    <CdPage
      pageHeaderProps={{
        extra: [
          <Button
            disabled={!hasUserAdminRole}
            type="primary"
            onClick={() => showCreateUserModal({})}
          >
            <Space>
              <FontAwesomeIcon icon={faUserPlus} />
              {gettextCatalog.getString('New user')}
            </Space>
          </Button>,
        ],
        title: gettextCatalog.getString('Users'),
        subTitle: gettextCatalog.getString(
          'Create, edit or delete users and manage access and permissions'
        ),
        backIcon: false,
      }}
    >
      <UserList />
    </CdPage>
  );
};
