import React from 'react';
import { Form, Typography } from 'antd';

import { useGetPortalsInfo } from '../../hooks/useCategories';

import { CdSelect } from '@/react/shared/components/cd-select/CdSelect';
import { gettextCatalog } from '@/react/services/I18nService';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';

interface MappingWithPortalCategoriesProps {
  type: string;
}
export const MappingWithPortalCategories = (
  props: MappingWithPortalCategoriesProps
) => {
  const { portals, isPortalMember } = useGetPortalsInfo();
  if (!isPortalMember) return null;
  return (
    <CdVerticalSpace>
      <Typography.Text strong>
        {gettextCatalog.getString('Mapping with portal categories')}
      </Typography.Text>
      {portals?.map((portal) => (
        <Form.Item
          key={portal.id}
          name={['portals', portal.id]}
          label={portal.name}
        >
          <CdSelect
            placeholder={gettextCatalog.getString('Choose a mapping category')}
            options={portal.portalCategories
              .filter((category) => category.type === props.type)
              .map((item) => ({
                label: item.name,
                value: item.id,
              }))}
          />
        </Form.Item>
      ))}
    </CdVerticalSpace>
  );
};
