import { useMutation, useSuspenseQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Form } from 'antd';

import {
  createTaxonomy,
  deleteTaxonomy,
  getCategories,
  getPartnersInfo,
  getPortalInfo,
  getTaxonomy,
  getTaxonomyPartnersMapping,
  getTaxonomyPortalMapping,
  updateTaxonomy,
} from '../services/categories';

import { CdClientSideTableApiSearchParam } from '@/react/shared/components/cd-client-side-table/types';
import { gettextCatalog } from '@/react/services/I18nService';
import { ModalType } from '@/react/shared/components/cd-modal-new/CdModal2';
import { handleSuccessMessage } from '@/react/shared/utils';
import { queryClient } from '@/react/shared/components/RootProvider';
import { Taxonomy } from '@/react/shared/models/taxonomy';
import { navigate } from '@/react/services/StateServiceFactory';

export const useCategories = ({
  setModalProps,
  type,
  taxonomyId,
  taxonomyName,
}) => {
  const [form] = Form.useForm();
  const { saveTaxonomy } = useCreateTaxonomyMutation(taxonomyId);
  const { editTaxonomy } = useUpdateTaxonomyMutation(taxonomyId);
  let title = '';
  if (taxonomyId) {
    title = gettextCatalog.getString('Edit "{{name}}"', {
      name: taxonomyName || form.getFieldValue('name'),
    });
  } else {
    switch (type) {
      case 'event':
        title = gettextCatalog.getString('Create event category');
        break;
      case 'blog':
        title = gettextCatalog.getString('Create blog category');
        break;
      case 'absence':
        title = gettextCatalog.getString('Create absence type');
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    setModalProps({
      title: title,
      modalType: ModalType.MODAL,
      width: 600,
      onOk: async () => {
        if (taxonomyId) {
          await form.validateFields().then((values) => {
            editTaxonomy({ id: taxonomyId, payload: { ...values, type } });
          });
        } else {
          await form.validateFields().then((values) => {
            saveTaxonomy({ payload: { ...values, type } });
          });
        }
        navigate('app.private.settings.calendar', {
          type: type,
          action: 'view',
          id: null,
        });
      },
      onCancel: () =>
        navigate(
          'app.private.settings.calendar',
          {
            type: type,
            action: 'view',
            id: null,
          },
          { reload: false }
        ),
    });
  }, [
    editTaxonomy,
    form,
    saveTaxonomy,
    setModalProps,
    taxonomyId,
    taxonomyName,
    title,
    type,
  ]);
  return {
    form,
  };
};

export const useCategoriesForm = ({ taxonomyId, form }) => {
  const taxonomy = useGetTaxonomy(taxonomyId);
  const taxonomyPortalMapping = useGetTaxonomyPortalMapping(taxonomyId);
  const taxonomyPartnersMapping = useGetTaxonomyPartnersMapping(taxonomyId);
  useEffect(() => {
    if (taxonomy?.data) {
      form.resetFields();
      form.setFieldsValue({ ...taxonomy.data });
      if (taxonomyPortalMapping?.data) {
        form.setFieldsValue({
          ...taxonomy.data,
          portals: taxonomyPortalMapping.data,
        });
      }
      if (taxonomyPartnersMapping?.data) {
        let partners = {};
        taxonomyPartnersMapping.data.forEach((item) => {
          partners = { ...partners, [item.partnerId]: item.partnerCategoryId };
        });
        form.setFieldsValue({ ...taxonomy.data, partners: partners });
      }
    }
  }, [
    form,
    taxonomy.data,
    taxonomyPartnersMapping.data,
    taxonomyPortalMapping.data,
  ]);
  return {
    isLoading: taxonomy?.isLoading || taxonomyPortalMapping?.isLoading,
  };
};

export const useGetAllCategories = (params: CdClientSideTableApiSearchParam) =>
  useSuspenseQuery({
    queryKey: [
      'useGetAllCategories',
      params?.extraData?.type,
      params.extraData,
    ],
    queryFn: async () => {
      const { type } = params.extraData;
      const response = await getCategories();
      const items = response?.filter((item) => item.type === type);
      return { items, total: items.length };
    },
  });

export const useGetPortalsInfo = () => {
  const { data, isLoading } = useSuspenseQuery({
    queryKey: ['useGetPortalsInfo'],
    queryFn: async () => {
      const response = await getPortalInfo();
      return response;
    },
  });

  return {
    portals: data?.portals,
    isLoading,
    isPortalHost: data?.isPortalHost,
    isPortalMember: data?.isPortalMember,
  };
};
export const useGetPartnersInfo = () => {
  const { data } = useSuspenseQuery({
    queryKey: ['useGetPartnersInfo'],
    queryFn: async () => {
      const response = await getPartnersInfo();
      return response;
    },
  });

  return {
    partners: data,
  };
};

const useGetTaxonomy = (taxonomyId: string) =>
  useSuspenseQuery({
    queryKey: ['useGettaxonomy', taxonomyId],
    queryFn: async () => (taxonomyId ? await getTaxonomy(taxonomyId) : null),
  });
const useGetTaxonomyPortalMapping = (taxonomyId: string) =>
  useSuspenseQuery({
    queryKey: ['useGetTaxonomyPortalMapping', taxonomyId],
    queryFn: async () =>
      taxonomyId ? await getTaxonomyPortalMapping(taxonomyId) : null,
  });
const useGetTaxonomyPartnersMapping = (taxonomyId: string) =>
  useSuspenseQuery({
    queryKey: ['useGetTaxonomyPartnersMapping', taxonomyId],
    queryFn: async () =>
      taxonomyId ? await getTaxonomyPartnersMapping(taxonomyId) : null,
  });
export const useCreateTaxonomyMutation = (taxonomyId) => {
  const { mutateAsync } = useMutation({
    mutationFn: async ({ payload }: { payload: Taxonomy }) =>
      await createTaxonomy(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useGetAllCategories'],
      });
      queryClient.invalidateQueries({
        queryKey: ['useGetTaxonomyPortalMapping', taxonomyId],
      });
      handleSuccessMessage(gettextCatalog.getString('Category created'));
    },
  });
  return { saveTaxonomy: mutateAsync };
};
export const useUpdateTaxonomyMutation = (taxonomyId) => {
  const { mutateAsync } = useMutation({
    mutationFn: async ({ id, payload }: { id: number; payload: Taxonomy }) =>
      await updateTaxonomy(id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useGetAllCategories'],
      });
      queryClient.invalidateQueries({
        queryKey: ['useGetTaxonomyPortalMapping', taxonomyId],
      });
      queryClient.invalidateQueries({
        queryKey: ['useGettaxonomy', taxonomyId],
      });
      handleSuccessMessage(gettextCatalog.getString('Category updated'));
    },
  });
  return { editTaxonomy: mutateAsync };
};

export const useDeleteCategories = ({ setModalProps, type, taxonomyId }) => {
  const [form] = Form.useForm();
  const shouldTransfer = Form.useWatch(['shouldTransfer'], form);
  let okText = '';
  switch (type) {
    case 'event':
      okText = gettextCatalog.getString('Delete and update events');
      break;
    case 'blog':
      okText = gettextCatalog.getString('Delete and update blogs');
      break;
    case 'absence':
      okText = gettextCatalog.getString('Delete and update absences');
      break;
    default:
      break;
  }
  const categoriesData = useGetAllCategories({
    extraData: { type },
  })?.data;
  const categories: { items: Taxonomy[]; total: number } = categoriesData || {
    items: [],
    total: 0,
  };
  const taxonomyName = categories?.items.find(
    (item) => item.id === taxonomyId
  )?.name;
  const options = categories?.items
    .filter((item) => item.type === type && item.id !== taxonomyId)
    .map((item) => ({ label: item.name, value: item.id }));
  const { deleteTaxonomy } = useDeleteTaxonomyMutation();
  useEffect(() => {
    setModalProps({
      title: gettextCatalog.getString('Delete {{type}} category', {
        type: type,
      }),
      width: 500,
      modalType: ModalType.MODAL,
      okText: shouldTransfer ? okText : gettextCatalog.getString('Delete'),
      okButtonProps: { danger: true },
      onOk: () => {
        if (shouldTransfer) {
          form.validateFields().then((values) => {
            deleteTaxonomy({ taxonomyId, replacementId: values.replacementId });
          });
        } else {
          deleteTaxonomy({ taxonomyId, replacementId: undefined });
        }
        navigate('app.private.settings.calendar', {
          type: type,
          action: 'view',
          id: null,
        });
      },
      onCancel: () =>
        navigate(
          'app.private.settings.calendar',
          {
            type: type,
            action: 'view',
            id: null,
          },
          { reload: false }
        ),
    });
  }, [
    deleteTaxonomy,
    form,
    okText,
    setModalProps,
    shouldTransfer,
    taxonomyId,
    type,
  ]);

  return {
    form,
    initialValues: { shouldTransfer: true, replacementId: options?.[0]?.value },
    isLoading: options === undefined,
    shouldTransfer,
    options: options,
    taxonomyName: taxonomyName,
  };
};

const useDeleteTaxonomyMutation = () => {
  const { mutateAsync } = useMutation({
    mutationFn: ({
      taxonomyId,
      replacementId,
    }: { taxonomyId: number; replacementId: number }) =>
      deleteTaxonomy(taxonomyId, replacementId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['useGetAllCategories'],
      });
      handleSuccessMessage(gettextCatalog.getString('Category deleted'));
    },
  });
  return { deleteTaxonomy: mutateAsync };
};
