/* eslint-disable */
/**
 * Globally available libraries
 */

import 'regenerator-runtime/runtime';
import 'jquery/dist/jquery';

import 'lodash';
import angular from 'angular';
import { Crisp } from 'crisp-sdk-web';
import ngRedux from 'ng-redux';
window.angular = angular;

import moment from 'moment-timezone';
import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/de-ch';
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/nl';
window.moment = moment;

/**
 * the `cdAppConfig` is a conditional constant, defined in the webpack config file
 */
window.cdApp = cdAppConfig; // eslint-disable-line

import * as Sentry from '@sentry/browser';
import { Angular as AngularIntegration } from '@sentry/integrations';
/**
 * Configure Sentry Browser
 */
Sentry.init({
  dsn:
    process.env.NODE_ENV === 'production'
      ? 'https://13b7d1324aab945c036aba4154536a09@e.churchdesk.com/4507345218240592'
      : '',
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    'Cannot resolve iframe with unknown index "2"',
    'callback is not a function',
    "Blocked 'font' from 'chrome-extension:'",
    'Unauthorized',
  ],
  allowUrls: ['.churchdesk.com'],
  beforeSend(event) {
    if (
      event?.exception?.values &&
      event?.exception?.values[0]?.stacktrace?.frames.some((frame) =>
        frame.filename.includes('fullview.io')
      )
    ) {
      // Mute Fullview.io
      return null;
    }

    return event;
  },
  integrations: [new AngularIntegration({ angular })],
  release: window.cdApp.release,
  environment: window.cdApp.config.environment,
});

// Bootstrap
import 'bootstrap/dist/js/bootstrap.min.js';

import './jquery-ui-local/jquery-ui.js';

/**
 * Full Calendar
 * @see https://github.com/ChurchDesk/fullcalendar
 */
import 'fullcalendar/dist/fullcalendar.min.js';

/**
 * Countries and currencies
 * @see https://github.com/ChurchDesk/iso-countries
 * @see https://github.com/zwacky/isoCurrency
 */
import 'iso-countries/dist/countries.min.js';
import 'iso-currency/dist/isoCurrency.min.js';

/**
 * The new Redactor 2 licensed for internal use in ChurchDesk
 * @see https://github.com/ChurchDesk/churchdesk-redactor2
 */
import 'churchdesk-redactor2/redactor.min.js';
import 'churchdesk-redactor2/dist/langs.js';
import 'churchdesk-redactor2/dist/plugins.js';

// CK Editor
import 'angular-ckeditor/angular-ckeditor.min.js';

/**
 * International Telephone Input jQuery plugin
 * @see https://github.com/jackocnr/intl-tel-input
 * @see https://github.com/ChurchDesk/churchdesk-intl-tel-input
 */
import 'intl-tel-input/build/js/intlTelInput.min.js';
import 'intl-tel-input/build/js/utils.js';
import 'churchdesk-intl-tel-input/dist/churchdesk-intl-tel-input.min.js';

import 'perfect-scrollbar/min/perfect-scrollbar.min.js';

import Uri from 'jsuri/Uri.js';
window.Uri = Uri;

/**
 * Qtip 2
 * @see https://github.com/qTip2/qTip2
 * @see https://github.com/chenasraf/ng-qtip2
 */
import 'qtip2/dist/jquery.qtip.min.js';
import 'ng-qtip2/ng-qtip2.js';

/**
 * Select2 dropdown jQuery plugin
 * @see https://github.com/select2/select2
 */
import 'select2/select2.min.js';
import 'select2/select2_locale_de.js';
import 'select2/select2_locale_da.js';
import 'select2/select2_locale_sv.js';
import 'select2/select2_locale_fr';

/**
 * A beautiful replacement for JavaScript's "alert"
 * @see https://github.com/t4t5/sweetalert
 */
import 'sweetalert/dist/sweetalert.min.js';

/**
 * A jQuery timepicker plugin
 * @see https://github.com/jonthornton/jquery-timepicker
 */
import 'timepicker/jquery.timepicker.min.js';
import 'angular-jquery-timepicker/src/timepickerdirective.min.js';

import 'tinycolor2';

// AngularJS modules
import 'angular-animate/angular-animate.min.js';
import 'angular-elastic';
import 'angular-file-upload/angular-file-upload.min.js';
import 'angular-gettext/dist/angular-gettext.min.js';
import 'angular-img-cropper/dist/angular-img-cropper.min.js';
import 'angular-jwt/dist/angular-jwt.min.js';
import 'angular-local-storage/dist/angular-local-storage.min.js';
import 'angular-messages';
import 'angular-moment';
import 'angular-poller/angular-poller.min.js';
import 'angular-redactor/angular-redactor-2.js';
import 'angular-resource/angular-resource.min.js';
import 'angular-sanitize/angular-sanitize.min.js';
import 'angular-utf8-base64/angular-utf8-base64.js';
import 'angularjs-stripe-elements/index.js';
import 'ng-file-upload/dist/ng-file-upload.min.js';
import 'ng-idle/angular-idle.min.js';
import 'ng-infinite-scroll/build/ng-infinite-scroll.min.js';
import 'angular-drag-and-drop-lists/angular-drag-and-drop-lists.min.js';
import 'bootstrap-ui-datetime-picker/dist/datetime-picker.min.js';
import 'angular-file-saver/dist/angular-file-saver.min.js';
import 'angular-toarrayfilter/toArrayFilter.js';

/**
 * @see https://github.com/chieffancypants/angular-hotkeys
 */
import 'angular-hotkeys/build/hotkeys.min.js';

/**
 * Angular Toastr
 * @see https://github.com/Foxandxss/angular-toastr
 */
import 'angular-toastr/dist/angular-toastr.tpls.min.js';

/**
 * Vanilla AngularJS Color Picker Directive
 * @see https://github.com/ruhley/angular-color-picker#readme
 */
import 'angularjs-color-picker';

// UI-Router
import '@uirouter/angularjs/release/angular-ui-router.min.js';
import '@uirouter/angularjs/release/stateEvents.min.js';
import 'angular-ui-router-styles/ui-router-styles.js';
import 'angular-ui-router-title/angular-ui-router-title.js';
import 'oclazyload';

// Angular-UI
import 'angular-ui-bootstrap/dist/ui-bootstrap-tpls.js';
import 'angular-ui-ace/src/ui-ace.js';
import 'angular-ui-mask/dist/mask.min.js';
import 'ui-select/dist/select.min.js';

/**
 * Charts for AngularJS using Chart.js
 * @see https://github.com/chartjs/Chart.js
 * @see https://github.com/jtblin/angular-chart.js
 */
import 'chart.js';
import 'angular-chart.js';

// Stripe dependency
import 'stripe-payment/dist/index';
const translatableStringsForStripePayment = () => {
  // This contains all the strings that are used in the stripe-payment module so that we can translate it in this project
  gettextCatalog.getString('We only accept the following cards:');
};

/**
 * ngDialog
 * @see https://github.com/likeastore/ngDialog
 */
import 'ng-dialog/js/ngDialog.min.js';

import 'ng-tags-input/build/ng-tags-input.min.js';

import 'ng-formio/dist/formio.min.js';
import 'ng-formio-builder/dist/ngFormBuilder.min.js';
import 'formio-custom/dist/formio-custom.min.js';
const translatableStringsForFormioCustom = () => {
  // This contains all the strings that are used in the formio-custom module so that we can translate it in this project
  gettextCatalog.getString('Sold out', null, 'forms');
};

import 'churchdesk-validate-cpr/dist/churchdesk-validate-cpr.min.js';

// eslint-disable-next-line no-unused-vars
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as ngimport from 'ngimport';
/**
 * External scripts
 */
import '../scripts/polyfills/custom-event.polyfill';

window.statuspalWidget = {
  subdomain: 'churchdesk-com-1',
  host: 'https://statuspal.eu',
  badge: {
    enabled: false,
    selector: '.sp-status',
  },
  banner: {
    enabled: true,
    position: 'bottom-right', // Optional [bottom-left | bottom-right | top-left | top-right], def: bottom-left
    translations: {
      en: {
        lates_updates: 'View latest updates',
        ongoing: 'Ongoing for {{time_diff}}',
      },
      da: {
        lates_updates: 'Vi seneste opdateringer',
        ongoing: 'Igangværende i {{time_diff}}',
      },
      de: {
        lates_updates: 'Neueste Updates',
        ongoing: 'Laufend seit {{time_diff}}',
      },
    },
  },
};

if (!window.cdApp.test) {
  const statuspalScript = document.createElement('script');
  statuspalScript.type = 'text/javascript';
  statuspalScript.src = 'https://statuspal.io/js/widget.js';
  document.head.appendChild(statuspalScript);

  require('../scripts/noticeable');
  Crisp.configure('4f054c00-397a-4689-864a-d3e4dd45cf8e');
  Crisp.chat.hide();
}

const getFromLocalStorage = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    return null;
  }
};
const setFromLocalStorage = (key) => {
  try {
    return localStorage.setItem(key);
  } catch (error) {
    return null;
  }
};

/**
 * Initialize the app with an organizationId
 */
const organizationIdFromParams = location.pathname.match(/\/o\/([\d]+)/);
const organizationIdFromStorage = parseInt(
  sessionStorage.getItem('churchdesk.organization') ||
    getFromLocalStorage('churchdesk.organization'),
  10
);

const organizationId = parseInt(
  (organizationIdFromParams || [])[1] || organizationIdFromStorage,
  10
);

if (organizationId) {
  window.churchdeskOrganizationId = organizationId;

  if (
    !getFromLocalStorage('churchdesk.organization') ||
    getFromLocalStorage('churchdesk.organization') !== organizationId
  ) {
    setFromLocalStorage('churchdesk.organization', organizationId);
  }
  sessionStorage.setItem('churchdesk.organization', organizationId);
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const shared = require('./shared/shared.module');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const calendar = require('./calendar/calendar.module');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const dashboard = require('./dashboard/dashboard.module');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const forms = require('./forms/forms.module');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const intranet = require('./intranet/intranet.module');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const people = require('./people/people.module');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const homepage = require('./homepage/homepage.module');
const safeguarding = require('./safeguarding/safeguarding.module');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const settings = require('./settings/settings.module');

angular.module('cdApp', [
  // external dependencies
  AngularIntegration.moduleName,
  'LocalStorageModule',
  'ngMessages',
  'ngResource',
  'ngSanitize',
  'ui.bootstrap',
  'ui.router',
  'oc.lazyLoad',
  'ui.router.state.events',
  'ui.select',
  'ui.timepicker',
  'angular-redactor',
  'gettext',
  'cdIntlTelInput',
  'toastr',
  'cfp.hotkeys',
  'angularMoment',
  'isoCurrency',
  'angular-img-cropper',
  'angularFileUpload',
  'ngQtip2',
  'angular-toArrayFilter',
  'ui.ace',
  'formio',
  'ngFormBuilder',
  'monospaced.elastic',
  'formioCustom',
  'utf8-base64',
  'infinite-scroll',
  'ngFileUpload',
  'cdValidateCpr',
  'color.picker',
  'emguo.poller',
  'ngIdle',
  'angular-jwt',
  'chart.js',
  'angularjs-stripe-elements',
  'bcherny/ngimport',
  ngRedux,
  'cdStripe',

  // internal dependencies
  shared,
  calendar,
  dashboard,
  intranet,
  forms,
  people,
  homepage,
  safeguarding,
  settings,
]);

require('./app.config');
require('./app.controller');
require('./app.routes');

require('./app.less');

angular.element(function () {
  angular.bootstrap(document.body, ['cdApp'], {
    strictDi: true,
  });
});
