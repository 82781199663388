import React, { useEffect } from 'react';
import { Col, Form, Input, Row } from 'antd';

import {
  createCdModal,
  ModalType,
} from '../../shared/components/cd-modal-new/CdModal2';
import { useSendEmailModal } from '../hooks/useSendEmailModal';

import { gettextCatalog } from '@/react/services/I18nService';

const { TextArea } = Input;
export const showSendUserEmailModal = createCdModal<{
  userId: number;
  userName: string;
  userEmail: string;
}>(({ setModalProps, userId, userEmail, userName }) => {
  const { sendEmailHandler, sendUserEmailForm } = useSendEmailModal(userId);

  useEffect(() => {
    setModalProps({
      modalType: ModalType.MODAL,
      title: gettextCatalog.getString('Contact {{user}}', {
        user: userName || userEmail,
      }),
      okText: gettextCatalog.getString('Send email'),
      onOk: sendEmailHandler,
    });
  }, [sendEmailHandler, setModalProps, userName, userEmail]);

  return (
    <Form layout="vertical" form={sendUserEmailForm}>
      <Row>
        <Col span={24}>
          <Form.Item
            name="subject"
            label={gettextCatalog.getString('Subject')}
            rules={[
              {
                required: true,
                message: gettextCatalog.getString('Please enter the subject'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="message"
            label={gettextCatalog.getString('Message')}
            rules={[
              {
                required: true,
                message: gettextCatalog.getString('Please enter the message'),
              },
            ]}
          >
            <TextArea />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});
