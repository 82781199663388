import * as _ from 'lodash';
import { $injector } from 'ngimport';
import moment from 'moment/moment';
import { parsePhoneNumber } from 'libphonenumber-js';

import { gettextCatalog } from '@/react/services/I18nService';
import { getCountryName } from '@/react/services/locale/country.service';

export function LightenDarkenColor(col: string, amt: number): string {
  if (!col || col === 'no-color') return;
  let usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function handleSuccessMessage(message: string): void {
  if (_.isEmpty(message)) return;
  const toastr: any = $injector.get('toastr');
  toastr.success(message);
}

/**
 * Format a phone number according to the specified parameters.
 *
 * @param {string} number The number to format
 * @param {string} countryCode The country code
 * @param {number} format The format code. Possible values: [E164 = 0, INTERNATIONAL = 1, NATIONAL = 2, RFC3966 = 3]. Default: 1 (INTERNATIONAL)
 * @returns {string} The formatted phone number
 */
export function formatPhoneNumber(
  number: string,
  countryCode?: string,
  format?: string
): string {
  if (_.isEmpty(number) || !window.intlTelInputUtils) return number;
  return window.intlTelInputUtils.formatNumber(
    number,
    countryCode,
    format || 1
  );
}

export const formatAddress = (address: {
  street?: string;
  postCode?: number | string;
  city?: string;
  countryIso2?: string;
}) => {
  if (address === undefined) {
    return undefined;
  }
  const postCodeAndCity = [address?.postCode, address?.city]
    .filter(Boolean)
    .join(' ');
  return [
    address?.street,
    postCodeAndCity,
    getCountryName(address?.countryIso2),
  ]
    .filter(Boolean)
    .join(', ');
};

export function getCommaFormattedNumber(num: number, numDecimals = 2): string {
  if (!num) return;
  if (Number.isInteger(num)) return num.toString();

  return num
    .toFixed(numDecimals) // always two decimal digits
    .replace('.', getDecimalSeparator()) // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + getThousandSeparator()); // https://blog.abelotech.com/posts/number-currency-formatting-javascript/
}

export function getDecimalSeparator() {
  const countryIso = getOrganizationIso();
  switch (countryIso) {
    case 'de':
    case 'dk':
    case 'se':
      return ',';
    default:
      return '.';
  }
}

export function getThousandSeparator() {
  const countryIso = getOrganizationIso();
  switch (countryIso) {
    case 'de':
    case 'dk':
    case 'se':
      return '.';
    default:
      return ',';
  }
}

export function getOrganizationCurrencySymbol() {
  return window.cdApp.organization.currency;
}

export function getOrganizationIso() {
  return window.cdApp.organization.countryIso2;
}

// this function is used to revert http encoded symbols
// like &gt and &lt to string
export const unEscape = (str: string) =>
  str
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&');

export const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const formatDecimalToTime = (hours: number) => {
  const duration = moment.duration(hours || 0, 'hours');
  const formattedDuration = gettextCatalog.getString(
    '{{hours}} hours {{minutes}} minutes',
    {
      hours: duration.hours(),
      minutes: duration.minutes(),
    }
  );
  return formattedDuration;
};

export const getParsedPhoneNumber = (phoneNumber: string) => {
  try {
    const phone = parsePhoneNumber(phoneNumber);
    if (phone) {
      return {
        phone: phone.nationalNumber,
        code: phone.countryCallingCode,
        short: phone.country,
      };
    }
  } catch (error) {
    return {
      phone: phoneNumber,
      code: null,
      short: null,
    };
  }
};
