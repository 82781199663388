import React, { useEffect } from 'react';

import { CdPage } from '../../../shared/components/cd-page/CdPage';
import gettextCatalog from '../../../services/I18nService';
import { CdTabs } from '../../../shared/components/cd-tabs/CdTabs';
import { OpenEnquiriesTab } from '../components/OpenEnquiriesTab';
import { ClosedEnquiriesTab } from '../components/ClosedEnquiriesTab';
import { showEnquiryDrawer } from '../components/EnquiryDrawer';

export const EnquiriesPage = (props: { id?: string }) => {
  useEffect(() => {
    props.id && showEnquiryDrawer({ id: props.id });
  }, [props]);

  return (
    <CdPage
      pageHeaderProps={{
        title: gettextCatalog.getString('Enquiries'),
        subTitle: gettextCatalog.getString(
          'Manage incoming enquiries from booking pages here'
        ),
      }}
    >
      <CdTabs
        items={[
          {
            title: gettextCatalog.getString('Open enquiries'),
            content: <OpenEnquiriesTab />,
          },
          {
            title: gettextCatalog.getString('Closed enquiries'),
            content: <ClosedEnquiriesTab />,
          },
        ]}
      />
    </CdPage>
  );
};
