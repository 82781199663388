import { Input, Space } from 'antd';
import React, { useMemo } from 'react';
import { useDebounceValue } from 'usehooks-ts';

import { useHasPrivilege } from '../../user/store/permissions';
import cdApp from '../../config';
import { openCopyShareableLinkModal } from '../../calendar/rota-scheme/CopyShareableLinkModal';
import { OrderType } from '../../shared/models/table';
import CdClientSideTable from '../../shared/components/cd-client-side-table/CdClientSideTable';
import { useTaxonomiesClientside } from '../../calendar/hooks/use-taxonomies';
import { CategoryType } from '../../shared/models/category';
import { CdButton } from '../../shared/components/cd-buttons/CdButton';

import gettextCatalog from '@/react/services/I18nService';
import { useBookingPagesActions } from '@/react/settings/booking-pages/hooks/UseBookingPagesActions';
import {
  CdDeleteIcon,
  CdEditIcon,
  CdLink,
  CdSearch,
  CdTableIcon,
} from '@/react/shared/components/Icons';
import { ColumnType } from '@/react/shared/components/cd-table';
import { AvailabilityTaxonomy } from '@/react/taxonomies/services/Taxonomies';

export const BookingPagesTable = () => {
  const { editBookingPage, deleteBookingPage } = useBookingPagesActions();
  const [search, setSearch] = useDebounceValue('', 300);
  const canEdit = useHasPrivilege('taxonomy.availability.edit');
  const canDelete = useHasPrivilege('taxonomy.availability.edit');

  const columns: ColumnType<AvailabilityTaxonomy>[] = useMemo(
    () => [
      {
        key: 'name',
        title: gettextCatalog.getString('Type'),
        orderType: OrderType.STRING,
        defaultSortOrder: 'ascend',
        dataIndex: 'name',
      },
      {
        key: 'config',
        title: gettextCatalog.getString('Public name'),
        orderType: OrderType.STRING,
        dataIndex: ['config', 'publicName'],
      },
      {
        key: 'actions',
        width: 200,
        render: (_, bookingPage) => (
          <Space>
            <CdButton
              type="primary"
              icon={<CdLink />}
              onClick={() =>
                openCopyShareableLinkModal({
                  title: gettextCatalog.getString('Share your booking page'),
                  url: `${cdApp.references.bookingPage}${cdApp.organization.publicId}/${bookingPage.alias}`,
                })
              }
            >
              {gettextCatalog.getString('Copy shareable link')}
            </CdButton>
            {canEdit && (
              <CdButton
                icon={<CdEditIcon />}
                onClick={() => editBookingPage(bookingPage)}
              >
                {gettextCatalog.getString('Edit')}
              </CdButton>
            )}
            {canDelete && (
              <CdButton
                icon={<CdDeleteIcon />}
                danger
                onClick={() => deleteBookingPage(bookingPage)}
              >
                {gettextCatalog.getString('Delete')}
              </CdButton>
            )}
          </Space>
        ),
      },
    ],
    [canDelete, canEdit, deleteBookingPage, editBookingPage]
  );

  return (
    <CdClientSideTable<AvailabilityTaxonomy>
      filterForm={
        <Input
          defaultValue={search}
          prefix={<CdSearch type="secondary" />}
          onChange={(e) => setSearch(e.target.value)}
          style={{ width: 300 }}
          placeholder={gettextCatalog.getString('Search...')}
        />
      }
      columns={columns}
      querySource={useTaxonomiesClientside}
      searchParams={{ type: CategoryType.availability, search }}
      emptyStateProps={{
        title: gettextCatalog.getString('No booking pages'),
        EmptyStateIcon: <CdTableIcon />,
      }}
    />
  );
};
