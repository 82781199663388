import {
  atom,
  selector,
  selectorFamily,
  useRecoilRefresher_UNSTABLE,
} from 'recoil';
import { uniqBy } from 'lodash';
import {
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { OrganizationFromSearch, SlimUser } from '../types/organization';
import { orderUsers } from '../services/organization.service';

import { ChurchSettingsState } from './church';

import { registerRecoilRefresher } from '@/app/cdRecoilRefresher';
import cdApp from '@/react/config';
import { mainApi } from '@/react/api';
import { ResourceTypes } from '@/react/shared/models/resource';
import { InterfaceGroups } from '@/react/organization/services/Groups.service';
import { Church } from '@/react/calendar/models/calendar';
import resourceService, {
  Resources,
} from '@/react/shared/services/ResourceService';
import { gettextCatalog } from '@/react/services/I18nService';
import { OrganizationSettings } from '@/react/organization/types/organization';

export const OrganizationsSearchText = atom({
  key: 'OrganizationsSearchText',
  default: '',
});

export const Organizations = atom({
  key: 'Organizations',
  default: selector({
    key: 'OrganizationsInitial',
    get: async () => {
      const { data } = await mainApi.get<OrganizationFromSearch[]>(
        '/organizations/search',
        {
          limit: 10,
          country: cdApp.organization.countryIso2,
        }
      );
      return uniqBy(
        data.concat(cdApp.organization as OrganizationFromSearch),
        'id'
      );
    },
  }),
});

export const SelectedOrganizationId = atom<number>({
  key: 'SelectedOrganizationId',
  default: selector({
    key: 'SelectedOrganizationIdInitial',
    get: () => cdApp.organization.id,
  }),
});

export const SelectedOrganization = atom({
  key: 'SelectedOrganization',
  default: selector({
    key: 'SelectedOrganizationInitial',
    get: () => cdApp.organization,
  }),
});

export const SelectedOrganizationChurches = atom({
  key: 'SelectedOrganizationChurches',
  default: selector({
    key: 'SelectedOrganizationChurchesInitial',
    get: () => cdApp.organization.churches,
  }),
});

export const OrganizationById = selectorFamily<OrganizationFromSearch, number>({
  key: 'OrganizationById',
  get:
    (id) =>
    async ({ get }) =>
      get(Organizations).find((o) => o.id === id),
  set:
    (id) =>
    ({ get, set }, value: OrganizationFromSearch) => {
      const organizations = get(Organizations);

      if (!organizations.find((o) => o.id === id)) {
        set(Organizations, (o) => o.concat(value));
      }
    },
});

export const OrganizationInfoByIdQuery = selectorFamily<
  OrganizationFromSearch,
  number
>({
  key: 'OrganizationInfoByIdQuery',
  get: (id: number) => async () => {
    const res = await mainApi.get<any>(`/organizations/${id}/public`);
    return res.data;
  },
});

/**
 * Atom to bust the cache of the user list for the current organization.
 * This is also used by other selectors to bust their cache if they depend on users.
 */
export const OrganizationUsersCache = atom<number>({
  key: 'OrganizationUsersCache',
  default: 0,
});

export const OrganizationUsers = selector<SlimUser[]>({
  key: 'OrganizationUsers',
  get: async ({ get }) => {
    get(OrganizationUsersCache);
    const { ok, data, originalError } =
      await mainApi.get<SlimUser[]>('/users/slim');
    if (ok) {
      return orderUsers(data);
    }
    throw originalError;
  },
});

export const OrganizationUsersIndexed = selector<{
  [userId: number]: SlimUser;
}>({
  key: 'OrganizationUsersIndexed',
  get: async ({ get }) => {
    const users = get(OrganizationUsers);
    const usersObject = users.reduce(
      (obj, user) => ({
        ...obj,
        [user.id]: user,
      }),
      {}
    );
    return usersObject;
  },
});

export const GetOrganizationUser = selectorFamily<
  {
    name: string;
    picture: string;
    status: 'blocked' | 'active' | 'createdWithoutLogin';
  },
  { id: number }
>({
  key: 'GetOrganizationUser',
  get:
    ({ id }) =>
    async ({ get }) => {
      const allUsers = get(OrganizationUsers);
      const user = allUsers.find((user) => user.id === id);
      if (user) {
        return { name: user.name, picture: user.picture, status: user.status };
      } else {
        return {
          name: gettextCatalog.getString('Unknown'),
          picture: null,
          status: null,
        };
      }
    },
});

export const OrganizationChurches = selector<Church[]>({
  key: 'OrganizationChurches',
  get: async ({ get }) => {
    const data = get(Resources);
    return data
      .filter((item) => item.type === ResourceTypes.CHURCH)
      .map((item) => {
        const { id, color, name } = item;
        return { id, name, color } as Church;
      });
  },
});

export const GetAllGroups = selector<InterfaceGroups[]>({
  key: 'GetAllGroups',
  get: async ({ getCallback }) => {
    const res = await mainApi.get<InterfaceGroups[]>('/groups');
    registerRecoilRefresher(
      GetAllGroups,
      getCallback(
        ({ refresh }) =>
          () =>
            refresh(GetAllGroups)
      )
    );
    if (res.ok) {
      return res.data;
    }
    throw res.originalError;
  },
});

export const useOrganizationSettings = () => {
  const queryClient = useQueryClient();
  const refreshChurchSettings =
    useRecoilRefresher_UNSTABLE(ChurchSettingsState);
  const query = useSuspenseQuery({
    queryKey: ['useOrganizationSettings'],
    queryFn: async () => {
      const response = await mainApi.get<OrganizationSettings>('church');
      if (response.ok) {
        return {
          displayOrder:
            localStorage.getItem('churchdesk.peopleSettings.displayOrder') ||
            'firstLast',
          ...response.data,
          newsletterColor: response.data?.newsletterColor || '#000',
        };
      }
      throw response;
    },
  });

  const refreshOrganizationSettings = () => {
    queryClient.invalidateQueries({
      queryKey: ['useOrganizationSettings'],
    });
    refreshChurchSettings();
  };

  return { organizationSettings: query.data, refreshOrganizationSettings };
};

export const useGroups = () => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ['useGroups'],
    queryFn: async () => {
      const res = await mainApi.get<InterfaceGroups[]>('/groups');
      if (res.ok) {
        return res.data;
      }
      throw res;
    },
  });

  const groups = query.data || [];
  const myGroups = groups.filter((item) => item.members.includes(cdApp.me.id));
  const myGroupsIds = myGroups.map((item) => item.id);
  const absenseGroups = myGroups.filter((item) => !item.absenceDisabled);

  const groupsWithUserAsMember = (
    userId: number,
    includeGroupsWithAbsenceDisabled = true
  ) =>
    query.data?.filter(
      (item) =>
        item.members.includes(Number(userId)) &&
        (includeGroupsWithAbsenceDisabled || !item.absenceDisabled)
    );

  const refreshGroups = () => {
    queryClient.invalidateQueries({
      queryKey: ['useGroups'],
    });
  };

  return {
    groups: query.data,
    absenseGroups,
    myGroupsIds,
    groupsWithUserAsMember,
    refreshGroups,
    isLoading: query.isLoading,
  };
};

export const useResources = () => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ['useResources'],
    queryFn: async () => resourceService.getResources(),
  });

  const refreshResources = () => {
    queryClient.invalidateQueries({
      queryKey: ['useResources'],
    });
  };

  return {
    resources: query.data,
    isLoading: query.isLoading,
    refreshResources,
  };
};
