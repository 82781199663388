import { useQuery, useQueryClient } from '@tanstack/react-query';

import { mainApi } from '../../api';
import { CategoryType } from '../../shared/models/category';
import {
  AvailabilityTaxonomy,
  Taxonomy,
} from '../../taxonomies/services/Taxonomies';
import NotificationService from '../../services/NotificationService';
import gettextCatalog from '../../services/I18nService';
import { CdClientSideTableApiSearchParam } from '../../shared/components/cd-client-side-table/types';

export const useTaxonomies = <T extends Taxonomy>(type: CategoryType) => {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ['useTaxonomies', type],
    queryFn: async () => {
      const response = await mainApi.get<T[]>(`taxonomies?type=${type}`);
      if (response.ok) {
        return response.data || [];
      }
      throw response;
    },
  });

  const refreshTaxonomies = () => {
    queryClient.invalidateQueries({
      queryKey: ['useTaxonomies'],
    });
  };

  return {
    taxonomies: query.data,
    refreshTaxonomies,
    isLoading: query.isLoading,
  };
};

export const useTaxonomiesClientside = <T extends Taxonomy>(
  params: CdClientSideTableApiSearchParam
) =>
  useQuery({
    queryKey: ['useTaxonomies', params.extraData.type, params.extraData.search],
    queryFn: async () => {
      const response = await mainApi.get<T[]>(
        `taxonomies?type=${params.extraData.type}`
      );
      if (response.ok) {
        const search = params.extraData.search?.toLowerCase();

        const searchIsIncluded = (item: T) => {
          if (search?.trim().length === 0 || !search) {
            return true;
          }

          const isNameIncluded = item.name?.toLowerCase().includes(search);
          const isPublicNameIncluded = (
            item as unknown as AvailabilityTaxonomy
          ).config?.publicName
            ?.toLowerCase()
            .includes(search);

          return isNameIncluded || isPublicNameIncluded;
        };

        const filtered = (response.data || []).filter(searchIsIncluded);

        return {
          items: filtered,
          total: filtered.length,
        };
      }
      throw response;
    },
  });

export const useDeleteTaxonomy = () => {
  const queryClient = useQueryClient();
  const deleteTaxonomy = async (id: number) => {
    await mainApi.delete(`taxonomies/${id}`);
    await queryClient.invalidateQueries({
      queryKey: ['useTaxonomies'],
    });
    NotificationService.notifySuccess(
      gettextCatalog.getString('Booking page deleted')
    );
  };

  return { deleteTaxonomy };
};
