import { react2angular } from 'react18-react2angular';

import sharedContext from '@/app/shared/components/shared-context-root/shared-context-root.component';
import { TaxonomyPageWrapper } from '@/react/settings/pages/categories/Categories';
angular
  .module('cdApp.calendar')
  .component(
    'cdrTaxonomyList',
    react2angular(sharedContext.use(TaxonomyPageWrapper), [], ['$stateParams'])
  );
