import React, { ReactNode } from 'react';
import { Typography, Divider, Row, Col, Spin, Button, Space } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { isArray } from 'lodash';

import { usePeopleContactPersonalInfo } from '../../hooks/useContactProfileInformation';
import { showEditPersonalInformationModal } from '../people-contacts-profile-modals/EditPersonalInformation';

import { InformationCard } from './InformationCard';

import { gettextCatalog } from '@/react/services/I18nService';
import {
  CdAllergyIcon,
  CdBaptismIcon,
  CdBasic,
  CdBirthDateIcon,
  CdCheckIcon,
  CdChurch,
  CdCityIcon,
  CdCivilStatusIcon,
  CdCountryIcon,
  CdDeathIcon,
  CdDietIcon,
  CdEditIcon,
  CdGenderIcon,
  CdHomePhone,
  CdLock,
  CdMedicalNoteIcon,
  CdMembershipIcon,
  CdMobile,
  CdNationalIdentificationNumberIcon,
  CdOccupationIcon,
  CdStreetIcon,
  CdUserAvatar,
  CdWeddingIcon,
  CdWorkPhone,
  CdZipCodeIcon,
  EventIcons,
} from '@/react/shared/components/Icons';
import { formatPhoneNumber } from '@/react/shared/utils';
import { getCountryName } from '@/react/services/locale/country.service';

const StyledDivider = styled(Divider)`
margin-top:0;
margin-bottom:0;
`;

const { Link } = Typography;
export const PeopleContactPersonalInfo = ({ contactId }) => {
  const { staticFields, profileInfo, staticFieldsOptions } =
    usePeopleContactPersonalInfo(contactId);
  return (
    <Spin spinning={!staticFields && !profileInfo}>
      <InformationCard
        title={gettextCatalog.getString('Personal information')}
        icon={<CdUserAvatar />}
        canView={true}
        hiddenTitle={gettextCatalog.getString('Hidden personal information')}
        headerExtraElements={
          profileInfo?.fields.email.canEdit ? (
            <Button
              type="link"
              icon={<CdEditIcon />}
              onClick={() => {
                showEditPersonalInformationModal({
                  title: gettextCatalog.getString('Personal information'),
                  contactId: contactId,
                  personFields: profileInfo.fields,
                  personRelations: profileInfo.relations,
                });
              }}
            >
              {gettextCatalog.getString('Edit')}
            </Button>
          ) : null
        }
      >
        <Category title={gettextCatalog.getString('Contact')} />
        <Field
          label={staticFields?.email.name}
          value={profileInfo?.email}
          icon={<CdBasic type="secondary" />}
          isEmail={true}
          isSensitive={staticFields?.email.sensitive}
          canView={profileInfo?.fields.email.canView}
        />
        <Field
          label={staticFields?.phone.name}
          value={profileInfo?.phone}
          icon={<CdMobile type="secondary" />}
          isPhone={true}
          isSensitive={staticFields?.phone.sensitive}
          canView={profileInfo?.fields.phone.canView}
        />
        <Field
          label={staticFields?.homePhone.name}
          value={profileInfo?.homePhone}
          icon={<CdHomePhone type="secondary" />}
          isPhone={true}
          isSensitive={staticFields?.homePhone.sensitive}
          canView={profileInfo?.fields.homePhone.canView}
        />
        <Field
          label={staticFields?.workPhone.name}
          value={profileInfo?.workPhone}
          icon={<CdWorkPhone type="secondary" />}
          isPhone={true}
          isSensitive={staticFields?.workPhone.sensitive}
          canView={profileInfo?.fields.workPhone.canView}
        />
        <Category title={gettextCatalog.getString('Address')} />
        <Field
          label={staticFields?.street?.name}
          value={profileInfo?.street}
          icon={<CdStreetIcon type="secondary" />}
          isSensitive={staticFields?.street.sensitive}
          canView={profileInfo?.fields.street.canView}
        />
        <Field
          label={staticFields?.city?.name}
          value={profileInfo?.city}
          icon={<CdCityIcon type="secondary" />}
          isSensitive={staticFields?.city.sensitive}
          canView={profileInfo?.fields.city.canView}
        />
        <Field
          label={staticFields?.zipcode?.name}
          value={profileInfo?.zipcode}
          icon={<CdZipCodeIcon type="secondary" />}
          isSensitive={staticFields?.zipcode.sensitive}
          canView={profileInfo?.fields.zipcode.canView}
        />
        <Field
          label={staticFields?.country?.name}
          value={
            profileInfo?.country ? getCountryName(profileInfo.country) : null
          }
          icon={<CdCountryIcon type="secondary" />}
          isSensitive={staticFields?.country.sensitive}
          canView={profileInfo?.fields.country.canView}
        />
        <Field
          label={staticFields?.poBoxNumber?.name}
          value={profileInfo?.poBoxNumber}
          icon={null}
          isSensitive={staticFields?.poBoxNumber.sensitive}
          canView={profileInfo?.fields.poBoxNumber.canView}
        />
        <Field
          label={staticFields?.poBoxPostCode?.name}
          value={profileInfo?.poBoxPostCode}
          icon={null}
          isSensitive={staticFields?.poBoxPostCode.sensitive}
          canView={profileInfo?.fields.poBoxPostCode.canView}
        />
        <Category title={gettextCatalog.getString('Other details')} />
        <Field
          label={staticFields?.gender?.name}
          value={
            profileInfo?.gender
              ? staticFieldsOptions?.gender[profileInfo.gender]
              : null
          }
          icon={<CdGenderIcon type="secondary" />}
          isSensitive={staticFields?.gender.sensitive}
          canView={profileInfo?.fields.gender.canView}
        />
        <Field
          label={staticFields?.occupation?.name}
          value={profileInfo?.occupation}
          icon={<CdOccupationIcon type="secondary" />}
          isSensitive={staticFields?.occupation.sensitive}
          canView={profileInfo?.fields.occupation.canView}
        />
        <Field
          label={staticFields?.note?.name}
          value={profileInfo?.note}
          icon={<EventIcons.InternalNote type="secondary" />}
          isSensitive={staticFields?.note.sensitive}
          canView={profileInfo?.fields.note.canView}
        />
        <Field
          label={staticFields?.civilStatus?.name}
          value={
            profileInfo?.civilStatus
              ? staticFieldsOptions?.civilStatus[profileInfo.civilStatus] ||
                profileInfo?.civilStatus
              : null
          }
          icon={<CdCivilStatusIcon type="secondary" />}
          isSensitive={staticFields?.civilStatus.sensitive}
          canView={profileInfo?.fields.civilStatus.canView}
        />
        <Field
          label={staticFields?.denomination?.name}
          value={
            profileInfo?.denomination
              ? staticFieldsOptions?.denomination[profileInfo.denomination] ||
                profileInfo?.denomination
              : null
          }
          icon={<CdChurch type="secondary" />}
          isSensitive={staticFields?.prefix.sensitive}
          canView={profileInfo?.fields.denomination.canView}
        />
        <Category title={gettextCatalog.getString('Life events')} />
        <Field
          label={staticFields?.birthday?.name}
          value={
            profileInfo?.birthday
              ? moment(profileInfo?.birthday).format(
                  gettextCatalog.getLongDateFormat('L')
                )
              : null
          }
          icon={<CdBirthDateIcon type="secondary" />}
          isSensitive={staticFields?.birthday.sensitive}
          canView={profileInfo?.fields.birthday.canView}
        />
        <Field
          label={staticFields?.baptismDate?.name}
          value={
            profileInfo?.baptismDate
              ? moment(profileInfo?.baptismDate).format(
                  gettextCatalog.getLongDateFormat('L')
                )
              : null
          }
          icon={<CdBaptismIcon type="secondary" />}
          isSensitive={staticFields?.baptismDate.sensitive}
          canView={profileInfo?.fields.baptismDate.canView}
        />
        <Field
          label={staticFields?.confirmationDate?.name}
          value={
            profileInfo?.confirmationDate
              ? moment(profileInfo?.confirmationDate).format(
                  gettextCatalog.getLongDateFormat('L')
                )
              : null
          }
          icon={<CdCheckIcon type="secondary" />}
          isSensitive={staticFields?.confirmationDate.sensitive}
          canView={profileInfo?.fields.confirmationDate.canView}
        />
        <Field
          label={staticFields?.civilMarriageDate?.name}
          value={
            profileInfo?.civilMarriageDate
              ? moment(profileInfo?.civilMarriageDate).format(
                  gettextCatalog.getLongDateFormat('L')
                )
              : null
          }
          icon={<CdWeddingIcon type="secondary" />}
          isSensitive={staticFields?.civilMarriageDate.sensitive}
          canView={profileInfo?.fields.civilMarriageDate.canView}
        />
        <Field
          label={staticFields?.membershipDate?.name}
          value={
            profileInfo?.membershipDate
              ? moment(profileInfo?.membershipDate).format(
                  gettextCatalog.getLongDateFormat('L')
                )
              : null
          }
          icon={<CdMembershipIcon type="secondary" />}
          isSensitive={staticFields?.membershipDate.sensitive}
          canView={profileInfo?.fields.membershipDate.canView}
        />
        <Field
          label={staticFields?.dateOfDeath?.name}
          value={
            profileInfo?.dateOfDeath
              ? moment(profileInfo?.dateOfDeath).format(
                  gettextCatalog.getLongDateFormat('L')
                )
              : null
          }
          icon={<CdDeathIcon type="secondary" />}
          isSensitive={staticFields?.dateOfDeath.sensitive}
          canView={profileInfo?.fields.dateOfDeath.canView}
        />
        <Category title={gettextCatalog.getString('Health')} />
        <Field
          label={staticFields?.nationalIdentificationNumber?.name}
          value={profileInfo?.nationalIdentificationNumber}
          icon={<CdNationalIdentificationNumberIcon type="secondary" />}
          isSensitive={staticFields?.nationalIdentificationNumber.sensitive}
          canView={profileInfo?.fields.nationalIdentificationNumber.canView}
        />
        <Field
          label={staticFields?.allergy?.name}
          value={
            isArray(profileInfo?.allergy) && profileInfo?.allergy.length > 0
              ? profileInfo?.allergy
                  .map((item) => staticFieldsOptions?.allergy[item])
                  .join(', ')
              : profileInfo?.allergy
          }
          icon={<CdAllergyIcon type="secondary" />}
          isSensitive={staticFields?.allergy.sensitive}
          canView={profileInfo?.fields.allergy.canView}
        />
        <Field
          label={staticFields?.dietary?.name}
          value={
            isArray(profileInfo?.dietary) && profileInfo?.dietary
              ? staticFieldsOptions?.dietary[profileInfo.dietary]
              : profileInfo?.dietary
          }
          icon={<CdDietIcon type="secondary" />}
          isSensitive={staticFields?.dietary.sensitive}
          canView={profileInfo?.fields.dietary.canView}
        />
        <Field
          label={staticFields?.medicalNote?.name}
          value={profileInfo?.medicalNote}
          icon={<CdMedicalNoteIcon type="secondary" />}
          isSensitive={staticFields?.medicalNote.sensitive}
          canView={profileInfo?.fields.medicalNote.canView}
        />
      </InformationCard>
    </Spin>
  );
};

const Field = ({
  label,
  value,
  icon,
  isEmail,
  isPhone,
  isSensitive,
  canView,
}: {
  label: string;
  value: string;
  icon: ReactNode;
  isEmail?: boolean;
  isPhone?: boolean;
  isSensitive: boolean;
  canView?: boolean;
}) => (
  <Row gutter={[16, 16]}>
    <Col span={1}>{icon}</Col>
    <Col span={11}>
      <Typography.Text type="secondary">{label}</Typography.Text>
    </Col>
    <Col span={12}>
      <Space>
        {isSensitive ? <CdLock type="secondary" /> : null}
        {value?.length ? (
          isEmail || isPhone ? (
            <Link href={isEmail ? `mailto:${value}` : `tel:${value}`}>
              {isPhone ? formatPhoneNumber(value) : value}
            </Link>
          ) : canView ? (
            value
          ) : (
            AccessErrorMessage()
          )
        ) : (
          <Typography.Text type="secondary">
            {gettextCatalog.getString('Unknown')}
          </Typography.Text>
        )}
      </Space>
    </Col>
  </Row>
);
const Category = ({ title }: { title: string }) => (
  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '12px' }}>
    <Typography.Text type="secondary">{title}</Typography.Text>
    <StyledDivider />
  </div>
);

export const AccessErrorMessage = () =>
  gettextCatalog.getString(
    'You do not have the permission to see this information.'
  );
