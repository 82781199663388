import React from 'react';
import { Form, Input, Spin } from 'antd';

import { useCategoriesForm } from '../../hooks/useCategories';

import { MappingWithPortalCategories } from './MappingWithPortalCategories';
import { MappingPartnerCategories } from './MappingPartnersCategories';

import { gettextCatalog } from '@/react/services/I18nService';
import { ColorSelector } from '@/react/calendar/components/ColorSelector';
import { CdCheckbox } from '@/react/shared/components/cd-checkbox/CdCheckbox';
import AuthorizationService from '@/react/services/AuthorizationService';

export const CreateCategoryForm = ({ taxonomyId, type, form }) => {
  const { isLoading } = useCategoriesForm({
    taxonomyId,
    form,
  });
  return (
    <Spin spinning={isLoading}>
      <Form layout="vertical" form={form}>
        <Form.Item
          name="name"
          label={gettextCatalog.getString('Name')}
          required
          rules={[
            {
              required: true,
              message: gettextCatalog.getString('Please enter a name.'),
            },
          ]}
        >
          <Input />
        </Form.Item>
        {type !== 'blog' && (
          <Form.Item name="color" initialValue={0} style={{ width: '500px' }}>
            <ColorSelector />
          </Form.Item>
        )}
        {type === 'event' && (
          <Form.Item
            name="registerAttendance"
            style={{ marginBottom: 0 }}
            valuePropName="checked"
          >
            <CdCheckbox>
              {gettextCatalog.getString(
                'Register attendance for this event category'
              )}
            </CdCheckbox>
          </Form.Item>
        )}
        {type === 'event' && AuthorizationService.hasPackage('intentions') && (
          <Form.Item name="declinePrimaryIntentions" valuePropName="checked">
            <CdCheckbox>
              {gettextCatalog.getString('No primary intentions allowed')}
            </CdCheckbox>
          </Form.Item>
        )}
        {type !== 'absence' && <MappingWithPortalCategories type={type} />}
        {type === 'event' && <MappingPartnerCategories />}
      </Form>
    </Spin>
  );
};
