import React from 'react';
import { Button, Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router';

import { useGetAllCategories } from '../../hooks/useCategories';

import CdClientSideTable from '@/react/shared/components/cd-client-side-table/CdClientSideTable';
import gettextCatalog from '@/react/services/I18nService';
import {
  CdAngleDown,
  CdCheckIcon,
  CdCircleFilled,
  CdDeleteIcon,
  CdEditIcon,
  CdOpenInNewWindow,
} from '@/react/shared/components/Icons';
import { ColumnType } from '@/react/shared/components/cd-table';
import { Taxonomy } from '@/react/shared/models/taxonomy';
import { COLOR_INDEX } from '@/app/ResourceColors';
import { OrderType } from '@/react/shared/models/table';
import AuthorizationService from '@/react/services/AuthorizationService';

export const CategoriesTable = ({ type }: { type: string }) => {
  const navigate = useNavigate();
  return (
    <CdClientSideTable<Taxonomy>
      noStyle
      key={type}
      searchParams={{ type }}
      querySource={useGetAllCategories}
      columns={getColumns(type, navigate)}
      rowKey="id"
      emptyStateProps={{
        title: gettextCatalog.getString('No categories found'),
      }}
      resetPaginationOnDataChange={false}
    />
  );
};

const getColumns = (type: string, navigate) => {
  const moreButtonItems = (record) => {
    const items = [
      {
        label: gettextCatalog.getString('Edit'),
        key: 'edit',
        icon: <CdEditIcon />,
        onClick: () => {
          navigate(
            `/o/${record.organizationId}/settings/calendar/${type}/edit/${record.id}`
          );
        },
      },
      {
        label: gettextCatalog.getString('Delete'),
        key: 'delete',
        icon: <CdDeleteIcon />,
        onClick: () => {
          navigate(
            `/o/${record.organizationId}/settings/calendar/${type}/delete/${record.id}`
          );
        },
      },
    ];
    if (type !== 'blog') {
      items.splice(1, 0, {
        label: gettextCatalog.getString('Export'),
        key: 'export',
        icon: <CdOpenInNewWindow />,
        onClick: () => {
          navigate(
            `/o/${record.organizationId}/settings/calendar/${type}/export/${record.id}`
          );
        },
      });
    }
    return items;
  };

  const columns: ColumnType<Taxonomy>[] = [
    {
      title: gettextCatalog.getString('Name'),
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      orderType: OrderType.STRING,
      render: (value, record) => (
        <Space key={`${record.id}`}>
          {type !== 'blog' && (
            <CdCircleFilled
              style={{
                color: COLOR_INDEX[record.color],
              }}
            />
          )}
          {value}
        </Space>
      ),
    },
  ];
  if (type === 'event') {
    columns.push({
      title: gettextCatalog.getString('Attendance'),
      dataIndex: 'registerAttendance',
      key: 'attendance',
      render: (value, record) =>
        value ? <CdCheckIcon key={`${record.id}`} type="success" /> : '',
    });
    if (AuthorizationService.hasPackage('intentions')) {
      columns.push({
        title: gettextCatalog.getString('No primary intentions'),
        dataIndex: 'declinePrimaryIntentions',
        key: 'declinePrimaryIntentions',
        render: (value, record) =>
          value ? <CdCheckIcon key={`${record.id}`} type="success" /> : '',
      });
    }
  }
  columns.push({
    dataIndex: 'buttons',
    title: '',
    key: 'buttons',
    align: 'right',
    width: 100,
    render: (value, record) => (
      <Dropdown
        menu={{
          items: moreButtonItems(record),
        }}
        destroyPopupOnHide={true}
      >
        <Button size="small">
          {gettextCatalog.getString('More')} <CdAngleDown />
        </Button>
      </Dropdown>
    ),
  });
  return columns;
};
