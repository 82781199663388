import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { CdClientSideTableApiSearchParam } from '@/react/shared/components/cd-client-side-table/types';
import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';
import { TagInterface } from '@/react/people/types/tag.type';
import { handleSuccessMessage } from '@/react/shared/utils';
import gettextCatalog from '@/react/services/I18nService';
import cdApp from '@/react/config';
import { navigate } from '@/react/services/StateServiceFactory';

export const GetTagList = (params: CdClientSideTableApiSearchParam) =>
  useQuery({
    queryKey: ['getTagList', params],
    queryFn: async () => {
      const { searchTag } = params.extraData;
      const response = await mainApi.get<TagInterface[]>(`people/tags`);
      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }
      const filteredData = response.data.filter((tag) =>
        tag.name.toLowerCase().includes(searchTag.toLowerCase())
      );
      return {
        items: filteredData.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 0 : -1
        ),
        total: filteredData?.length,
      };
    },
  });
export const useCreateOrEditTag = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async ({
      name,
      data,
      isEdit,
    }: { name: string; data: TagInterface; isEdit: boolean }) => {
      if (isEdit) {
        const res = await mainApi.put(`/people/tags/${data.id}`, {
          ...data,
          name: name,
        });
        if (!res.ok) {
          handleError(res);
          return undefined;
        } else {
          handleSuccessMessage(
            gettextCatalog.getString('Tag updated successfully.')
          );
        }
      } else {
        const res = await mainApi.post(`/people/tags`, {
          name: name,
          isTag: true,
        });
        if (!res.ok) {
          handleError(res);
          return undefined;
        } else {
          handleSuccessMessage(
            gettextCatalog.getString('Tag created successfully.')
          );
        }
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getTagList'],
      });
    },
  });
  return {
    createOrEditTag: mutate,
  };
};

export const useDeleteTag = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: async (tagId: number) => {
      const res = await mainApi.delete(`/people/tags/${tagId}`);
      if (!res.ok) {
        handleError(res);
        return undefined;
      } else {
        handleSuccessMessage(
          gettextCatalog.getString('Tag deleted successfully.')
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getTagList'],
      });
    },
  });
  return {
    deleteTag: mutate,
    isPending,
  };
};

export const useCreateTagFilter = () => {
  const { mutate } = useMutation({
    mutationFn: async ({
      filterName,
      data,
    }: { filterName: string; data: TagInterface }) => {
      const res = await mainApi.post(`/people/segments`, {
        name: filterName,
        setup: {
          comparison: 'AND',
          churchIds: cdApp.organization.churches.map((church) => church.id),
          filters: [
            {
              type: 'list',
              property: 'tags',
              operator: 'eq',
              value: data.id,
            },
          ],
        },
      });

      if (!res.ok) {
        handleError(res);
        return undefined;
      } else {
        handleSuccessMessage(
          gettextCatalog.getString('Filter created successfully.')
        );
      }

      return res.data;
    },
    onSuccess: (data: { id: number }) => {
      navigate(`app.private.people.contacts.list`, { list: data.id });
    },
  });
  return {
    createTagFilter: mutate,
  };
};

export const useCreateOneTag = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: async ({
      name,
      setNewTag,
    }: { name: string; setNewTag: (number) => void }) => {
      const res = await mainApi.post<{ id: number }>(`/people/tags`, {
        name: name,
        isTag: true,
      });
      if (!res.ok) {
        handleError(res);
        return undefined;
      } else {
        setNewTag(res.data?.id);
        handleSuccessMessage(
          gettextCatalog.getString('Tag created successfully.')
        );
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['getTagList'],
      });
    },
  });
  return {
    mutateOneTag: mutate,
  };
};
