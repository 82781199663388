import React, { Suspense } from 'react';
import { Button, Space } from 'antd';
import Search from 'antd/es/input/Search';

import CdrPageLoader from '@/react/shared/components/CdrPageLoader';
import { CdPage } from '@/react/shared/components/cd-page/CdPage';
import gettextCatalog from '@/react/services/I18nService';
import {
  CdDeleteIcon,
  CdEditIcon,
  CdFilterRegular,
  CdTags,
} from '@/react/shared/components/Icons';
import CdClientSideTable from '@/react/shared/components/cd-client-side-table/CdClientSideTable';
import { tagListColumn } from '@/react/people/tag-list/component/TagListColumn';
import { GetTagList } from '@/react/people/tag-list/store/tags';
import { openCreateTagModal } from '@/react/people/tag-list/component/CreateTagModal';
import { TagInterface } from '@/react/people/types/tag.type';
import { useTagList } from '@/react/people/tag-list/hooks/useTagList';
import { onCreateFilter } from '@/react/people/tag-list/component/CreateFilterModal';

export const TagListWrapper = () => (
  <Suspense fallback={<CdrPageLoader marginLess />}>
    <TagList />
  </Suspense>
);

function TagList() {
  const {
    searchTag,
    onDeleteTag,
    isDeletePending,
    handleSearch,
    canManageTags,
    canCreateList,
    canDeleteTags,
  } = useTagList();
  return (
    <CdPage
      pageHeaderProps={{
        title: gettextCatalog.getString('Tags'),
        subTitle: gettextCatalog.getString(
          'Manage your tags and see how many contacts they are attached to'
        ),
        backIcon: false,
        extra: (
          <Button
            type="primary"
            disabled={!canManageTags}
            onClick={() =>
              openCreateTagModal({ data: undefined, isEdit: false })
            }
          >
            {gettextCatalog.getString('Create')}
          </Button>
        ),
      }}
    >
      {isDeletePending ? (
        <CdrPageLoader />
      ) : (
        <CdClientSideTable
          searchParams={{ searchTag: searchTag }}
          querySource={GetTagList}
          columns={tagListColumn()}
          emptyStateProps={{
            title: gettextCatalog.getString('No tags created yet'),
            subtitle: gettextCatalog.getString(
              'Create one now to start segmenting contacts.'
            ),
            EmptyStateIcon: <CdTags />,
            buttonAction: (
              <Button
                type="primary"
                disabled={!canManageTags}
                onClick={() =>
                  openCreateTagModal({ data: undefined, isEdit: false })
                }
              >
                {gettextCatalog.getString('Create tag')}
              </Button>
            ),
          }}
          filterForm={
            <Space>
              <Search
                onSearch={handleSearch}
                placeholder={gettextCatalog.getString('Search for tags')}
              />
            </Space>
          }
          actionButtons={[
            {
              text: gettextCatalog.getString('Create as filter'),
              icon: <CdFilterRegular fixedWidth />,
              disabled: !canCreateList,
              onClick: (data) => {
                onCreateFilter({ data });
              },
            },
            {
              text: gettextCatalog.getString('Rename'),
              icon: <CdEditIcon fixedWidth />,
              disabled: !canManageTags,
              onClick: (data: TagInterface) =>
                openCreateTagModal({ data, isEdit: true }),
            },
            {
              text: gettextCatalog.getString('Delete tag'),
              icon: <CdDeleteIcon fixedWidth />,
              disabled: !canDeleteTags,
              onClick: (data) => onDeleteTag(data.id),
            },
          ]}
        />
      )}
    </CdPage>
  );
}
