import { Button, Drawer, Form, Input, Space, Spin } from 'antd';
import React, { useState } from 'react';
import NiceModal, { antdDrawerV5, useModal } from '@ebay/nice-modal-react';
import styled from 'styled-components';
import { useRecoilValueLoadable } from 'recoil';

import { PeopleListByTypeAndSearchQuery } from '../../store/newsletterListState';
import { GetTags } from '../../store/tagState';

import ManageContactsDrawerForm from './ManageContactsDrawerForm';

import { UserChurchesWithPermissionQuery } from '@/react/user/store/permissions';
import {
  CdNewsletterListIcon,
  CdChurch,
  CdParticipantList,
  CdTags,
} from '@/react/shared/components/Icons';
import { gettextCatalog } from '@/react/services/I18nService';

const CdDrawer = styled(Drawer)`
  &&&& {
    .ant-drawer-body {
      padding: 0px;
      display: flex;
    }
    .ant-layout {
      .ant-layout-sider {
        padding: 8px 24px;
      }
      .ant-layout-content {
        padding: 8px;
        overflow: auto;
      }
    }
  }
`;
const Container = styled.main`
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: '400px';
`;
const Search = styled(Input)`
  &&&& {
    margin-bottom: 8px;
    width: 100%;
  }
`;

export interface ManageContactsDrawerProps extends Record<string, unknown> {
  type: ManagementContactType;
  selectedPeople: {
    contactId: number;
    fields: { property: string; value: { value: number; label: string }[] }[];
    id: number;
  }[];
  isGlobalSelect: boolean;
  filter: { churchIds: number[]; comparison: string; filters: [] };
}
export enum ManagementContactType {
  Newsletter = 'peopleNewsletterLists',
  Parish = 'churches',
  Tag = 'tags',
  Participant = 'participantLists',
}
export const ManageContactsDrawer = NiceModal.create(
  ({
    type,
    selectedPeople,
    isGlobalSelect,
    filter,
  }: ManageContactsDrawerProps) => (
    <ManageContacts
      type={type}
      selectedPeople={selectedPeople}
      isGlobalSelect={isGlobalSelect}
      filter={filter}
    />
  )
);
export const ManageContacts = ({
  type,
  selectedPeople,
  isGlobalSelect,
  filter,
}: ManageContactsDrawerProps) => {
  const modal = useModal('ManageContactsDrawer');
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const [formInitialValue, setFormInitialValue] = useState([]);
  const [participantFormInitialValue, setParticipantFormInitialValue] =
    useState([]);
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const [participantForm] = Form.useForm();
  let title: string;
  switch (type) {
    case ManagementContactType.Newsletter:
      title = gettextCatalog.getString('Add/remove contacts from lists');
      break;
    case ManagementContactType.Parish:
      title = gettextCatalog.getString('Add/remove contacts from parishes');
      break;
    case ManagementContactType.Tag:
      title = gettextCatalog.getString('Add/remove tags from contacts');
      break;
  }
  const newsletterListDataLoading = useRecoilValueLoadable(
    PeopleListByTypeAndSearchQuery({
      type: 'newsletter',
      searchParameter: searchKeyWord,
    })
  );
  const participantListDataloading = useRecoilValueLoadable(
    PeopleListByTypeAndSearchQuery({
      type: 'participant',
      searchParameter: searchKeyWord,
    })
  );

  const { tagsData, tagIsLoading } = GetTags(searchKeyWord);
  const parishesLoadable = useRecoilValueLoadable(
    UserChurchesWithPermissionQuery({
      permissionContext: 'people',
      permissionType: 'access',
    })
  );
  const newsletterListData =
    newsletterListDataLoading.state === 'hasValue'
      ? newsletterListDataLoading.contents.items.filter(
          ({ canAddPeopleToList }) => canAddPeopleToList
        )
      : [];
  const participantListData =
    participantListDataloading.state === 'hasValue'
      ? participantListDataloading.contents.items
      : [];

  const isLoading =
    newsletterListDataLoading.state === 'loading' ||
    participantListDataloading.state === 'loading' ||
    tagIsLoading ||
    parishesLoadable.state === 'loading';
  const searched = (keyWord) => (l) =>
    l.name.toLowerCase().includes(keyWord.toLowerCase());
  const parishesData =
    parishesLoadable.state === 'hasValue'
      ? parishesLoadable.contents.filter(searched(searchKeyWord))
      : [];
  // check for each checkbox to see if the id is in the selectedPeople items or not
  const doesItemBelongToAllPeople = (id: number, property: string) =>
    selectedPeople.every((person) => {
      const tagField = person.fields.find(
        (field) => field.property === property
      );
      if (!tagField) return false;
      const value = tagField.value;
      if (typeof value === 'string') return false;
      return tagField?.value?.some((val) => val.value === id);
    });

  const doesItemBelongToSomePeople = (id: number, property: string) => {
    // Don't process whether it should be checked or not. All Items are indeterminate when isGlobalSelect=true
    if (isGlobalSelect) {
      return true;
    } else {
      const belongToSomeBody = selectedPeople?.some((person) => {
        const tagField = person.fields.find(
          (field) => field.property === property
        );
        if (!tagField) return false;
        const value = tagField.value;
        if (typeof value === 'string') return false;
        return tagField?.value?.some((val) => val.value === id);
      });
      if (belongToSomeBody && !doesItemBelongToAllPeople(id, property)) {
        return true;
      } else {
        return false;
      }
    }
  };
  const HandleAddAndRemove = () => {
    let add = [];
    let remove = [];
    let isNesletterAdd = false;
    let newsletterAdd = 0;
    let newsletterRemove = 0;
    let participantAdd = 0;
    let participantRemove = 0;
    const formValues = form?.getFieldsValue(true).list
      ? Object.entries(form?.getFieldsValue(true).list).map(([id, value]) => ({
          id: +id,
          value: value,
        }))
      : [];
    const participantFormValue = participantForm?.getFieldsValue(true).list
      ? Object.entries(participantForm.getFieldsValue(true)?.list)?.map(
          ([id, value]) => ({ id: +id, value: value })
        )
      : [];

    if (formValues?.length > 0) {
      const addedItems = formValues.filter(
        (item, index) =>
          item.value !== formInitialValue[index].value &&
          item.value === 'checked'
      );
      const removedItems = formValues.filter(
        (item, index) =>
          item.value !== formInitialValue[index].value && item.value === false
      );
      add = add.concat(addedItems?.map((item) => item.id));
      remove = remove.concat(removedItems?.map((item) => item.id));
      newsletterAdd = addedItems ? addedItems.length : 0;
      newsletterRemove = removedItems ? removedItems.length : 0;
      if (addedItems?.length > 0 && type === ManagementContactType.Newsletter) {
        isNesletterAdd = true;
      }
    }
    if (participantFormValue?.length > 0) {
      const addedItems = participantFormValue?.filter(
        (item, index) =>
          item.value !== participantFormInitialValue[index].value &&
          item.value === 'checked'
      );
      const removedItems = participantFormValue?.filter(
        (item, index) =>
          item.value !== participantFormInitialValue[index].value &&
          item.value === false
      );
      add = add.concat(addedItems?.map((item) => item.id));
      remove = remove.concat(removedItems?.map((item) => item.id));
      participantAdd = addedItems ? addedItems.length : 0;
      participantRemove = removedItems ? removedItems.length : 0;
    }

    showConfirmManageContactsModal(
      type,
      selectedPeople,
      isGlobalSelect,
      isNesletterAdd,
      { add, remove },
      filter,
      { newsletterAdd, newsletterRemove, participantAdd, participantRemove }
    );
    modal.hide();
  };
  const showConfirmManageContactsModal = (
    type: ManagementContactType,
    selectedPeople,
    isGlobalSelect: boolean,
    isNesletterAdd: boolean,
    result: { add: number[]; remove: number[] },
    filter,
    newsletterAndParticipantInfo: {
      newsletterAdd: number;
      newsletterRemove: number;
      participantAdd: number;
      participantRemove: number;
    }
  ) => {
    NiceModal.show('ConfirmManageContacts', {
      type,
      selectedPeople,
      isGlobalSelect,
      isNesletterAdd,
      result,
      filter,
      newsletterAndParticipantInfo,
    });
  };

  return (
    <CdDrawer
      {...antdDrawerV5(modal)}
      width="400px"
      title={<span style={{ paddingRight: '8px' }}>{title}</span>}
      footerStyle={{ textAlign: 'right' }}
      footer={
        <Space>
          <Button onClick={modal.hide}>
            {gettextCatalog.getString('Cancel')}
          </Button>
          <Button
            type="primary"
            disabled={disabled}
            onClick={() => {
              HandleAddAndRemove();
            }}
          >
            {gettextCatalog.getString('Apply')}
          </Button>
        </Space>
      }
    >
      <div style={{ width: '100%' }}>
        <Spin spinning={isLoading}>
          <Container>
            <Search
              placeholder={gettextCatalog.getString('Search for list')}
              value={searchKeyWord}
              autoFocus
              onChange={(e) => setSearchKeyWord(e.target.value)}
            />

            {type === ManagementContactType.Newsletter && (
              <>
                <ManageContactsDrawerForm
                  label={gettextCatalog.getString('Newsletter list')}
                  list={newsletterListData}
                  noDataLabel={gettextCatalog.getString('No newsletters')}
                  type={ManagementContactType.Newsletter}
                  modal={modal}
                  url="app.private.people.lists2"
                  param={{
                    createList: true,
                    activeTab: 'newsletterLists',
                  }}
                  doesItemBelongToAllPeople={doesItemBelongToAllPeople}
                  doesItemBelongToSomePeople={doesItemBelongToSomePeople}
                  form={form}
                  setFormInitialValue={setFormInitialValue}
                  formInitialValue={formInitialValue}
                  setDisabled={setDisabled}
                  icon={<CdNewsletterListIcon />}
                />
                <ManageContactsDrawerForm
                  label={gettextCatalog.getString('Participant list')}
                  list={participantListData}
                  noDataLabel={gettextCatalog.getString('No participants')}
                  type={ManagementContactType.Participant}
                  modal={modal}
                  url="app.private.people.lists2"
                  param={{
                    createList: true,
                    activeTab: 'participantLists',
                  }}
                  doesItemBelongToAllPeople={doesItemBelongToAllPeople}
                  doesItemBelongToSomePeople={doesItemBelongToSomePeople}
                  form={participantForm}
                  setFormInitialValue={setParticipantFormInitialValue}
                  formInitialValue={participantFormInitialValue}
                  setDisabled={setDisabled}
                  icon={<CdParticipantList />}
                />
              </>
            )}
            {type === ManagementContactType.Tag && (
              <>
                <ManageContactsDrawerForm
                  label={gettextCatalog.getString('Tags')}
                  list={tagsData}
                  noDataLabel={gettextCatalog.getString('No tags')}
                  type={ManagementContactType.Tag}
                  modal={modal}
                  url="app.private.people.tagList"
                  param={null}
                  doesItemBelongToAllPeople={doesItemBelongToAllPeople}
                  doesItemBelongToSomePeople={doesItemBelongToSomePeople}
                  form={form}
                  setFormInitialValue={setFormInitialValue}
                  formInitialValue={formInitialValue}
                  setDisabled={setDisabled}
                  icon={<CdTags />}
                />
              </>
            )}
            {type === ManagementContactType.Parish && (
              <>
                <ManageContactsDrawerForm
                  label={gettextCatalog.getString('Parishes')}
                  icon={<CdChurch />}
                  list={parishesData}
                  noDataLabel={gettextCatalog.getString('No parishes')}
                  type={ManagementContactType.Parish}
                  modal={modal}
                  url="app.private.settings.resources"
                  param={{}}
                  doesItemBelongToAllPeople={doesItemBelongToAllPeople}
                  doesItemBelongToSomePeople={doesItemBelongToSomePeople}
                  formInitialValue={formInitialValue}
                  setDisabled={setDisabled}
                  form={form}
                  setFormInitialValue={setFormInitialValue}
                />
              </>
            )}
          </Container>
        </Spin>
      </div>
    </CdDrawer>
  );
};
