import { useQuery } from '@tanstack/react-query';

import { TagInterface } from '../types/tag.type';

import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const GetTags = (searchParameter?: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['getTagList', searchParameter],
    queryFn: async () => {
      const response = await mainApi.get<TagInterface[]>(`people/tags`);
      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }
      const filteredData = response.data.filter((tag) =>
        tag.name.toLowerCase().includes(searchParameter?.toLowerCase())
      );
      return {
        items: filteredData.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 0 : -1
        ),
        total: filteredData?.length,
      };
    },
  });
  return { tagsData: data?.items || [], tagIsLoading: isLoading };
};
