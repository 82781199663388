import { isEmpty } from 'lodash';

import { MappedPartner, Partner } from '../types/partner.type';

import { mainApi, partnershipApi } from '@/react/api';
import { OrganizationPortals } from '@/react/portal/types/portal.type';
import { handleError } from '@/react/services/ErrorHandlingService';
import { Taxonomy } from '@/react/shared/models/taxonomy';

export const getCategories = async (): Promise<Taxonomy[]> => {
  const response = await mainApi.get('taxonomies');
  if (response.ok) {
    return response.data as Taxonomy[];
  }
  handleError(response);
  return [];
};

export const getPortalInfo = async () => {
  const response = await mainApi.get<OrganizationPortals>(
    `/portal/portals/organization`
  );
  if (!response.ok) {
    handleError(response);
    return null;
  }
  return response.data;
};

export const getPartnersInfo = async () => {
  const response = await partnershipApi.get<Partner[]>(`/partners/categories`);
  if (!response.ok) {
    handleError(response);
    return null;
  }
  return response.data;
};
export const getTaxonomy = async (id: string) => {
  const response = await mainApi.get(`taxonomies/${id}`);
  if (response.ok) {
    return response.data as Taxonomy;
  }
  handleError(response);
};

export const getTaxonomyPortalMapping = async (id: string) => {
  const response = await mainApi.get(`portal/categories/mapping/${id}`);
  if (response.ok) {
    return response.data;
  }
  handleError(response);
};

export const getTaxonomyPartnersMapping = async (id: string) => {
  const response = await partnershipApi.get(`categories/${id}`);
  if (response.ok) {
    return response.data as MappedPartner[];
  }
  handleError(response);
};
export const createTaxonomy = async (payload: Taxonomy) => {
  const response = await mainApi.post<Taxonomy>('taxonomies', payload);
  if (response.ok) {
    if (
      (payload.type === 'event' || payload.type === 'blog') &&
      !isEmpty(payload.portals)
    ) {
      const body = {
        [`${payload.type}CategoryId`]: response.data.id,
        portalCategories: Object.values(payload.portals).filter(Boolean),
      };
      const taxonomyMappingRespone = await mainApi.post(
        'portal/categories/mapping',
        body
      );
      if (!taxonomyMappingRespone.ok) {
        handleError(taxonomyMappingRespone);
      }
    }
    if (payload.type === 'event' && !isEmpty(payload.partners)) {
      const body = {
        categoryId: response.data.id,
        partners: payload.partners,
      };
      const partnersMappingRespone = await partnershipApi.post(
        `categories/${response.data.id}`,
        body
      );
      if (!partnersMappingRespone.ok) {
        handleError(partnersMappingRespone);
      }
    }
    return true;
  }
  handleError(response);
};
export const updateTaxonomy = async (id: number, payload: Taxonomy) => {
  const response = await mainApi.put(`taxonomies/${id}`, payload);
  if (response.ok) {
    if (
      (payload.type === 'event' || payload.type === 'blog') &&
      !isEmpty(payload.portals)
    ) {
      const body = {
        [`${payload.type}CategoryId`]: id,
        portalCategories: Object.values(payload.portals).filter(Boolean),
      };
      const taxonomyMappingRespone = await mainApi.post(
        'portal/categories/mapping',
        body
      );
      // don't show the error message for confilct mapping status:409
      if (!taxonomyMappingRespone.ok && taxonomyMappingRespone.status !== 409) {
        handleError(taxonomyMappingRespone);
      }
    }
    if (payload.type === 'event' && !isEmpty(payload.partners)) {
      const body = {
        categoryId: id,
        partners: payload.partners,
      };
      const partnersMappingRespone = await partnershipApi.post(
        `categories/${id}`,
        body
      );
      if (!partnersMappingRespone.ok) {
        handleError(partnersMappingRespone);
      }
    }
    return true;
  }
  handleError(response);
};

export const deleteTaxonomy = async (id: number, replacementId: number) => {
  const response = await mainApi.delete(`taxonomies/${id}`, { replacementId });
  if (response.ok) {
    return true;
  }
  handleError(response);
};
