import { useModal } from '@ebay/nice-modal-react';
import { Form, theme } from 'antd';
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useRecoilRefresher_UNSTABLE as useRecoilRefresher,
} from 'recoil';
import { useCallback, useState } from 'react';

import { GetAssignableUsersForSpecificRotaDuty } from '../../store/events/eventRotaDuties';
import { CheckUsersAvailablility, EventQuery } from '../../store/events/event';
import { RotaDetails } from '../components/rotas/AddUserToRotaModal';
import { DetailedEvent } from '../../models/calendar';
import StateServiceFactory from '../../../services/StateServiceFactory';

import { showConflicts } from './use-event';
import { useRotaAndIntentionState } from './use-rota-and-intention-state';

import { SlimUser } from '@/react/organization/types/organization';
import {
  OrganizationChurches,
  OrganizationUsersIndexed,
} from '@/react/organization/store/organization';
import { orderUsers } from '@/react/organization/services/organization.service';
import NotificationService from '@/react/services/NotificationService';
import { gettextCatalog } from '@/react/services/I18nService';
import { mainApi } from '@/react/api';
import { handleError } from '@/react/services/ErrorHandlingService';

export const useAddUserToRota = ({
  rotaDetails,
  initialRrule,
  defaultParishes,
  otherShifts,
}: {
  rotaDetails: RotaDetails;
  initialRrule: string;
  defaultParishes: number[];
  otherShifts: Shift[];
}) => {
  const modal = useModal('AddUserToRotaModal');
  const { useToken } = theme;
  const { token } = useToken();
  const [form] = Form.useForm();
  const { updateRotaTableStateToAddUserToTheRota } = useRotaAndIntentionState();
  const [filterParishIds, setFilterParishIds] =
    useState<number[]>(defaultParishes);
  const { startDate, endDate, rrule, allDay } = rotaDetails.date;
  const usersLoadable = useRecoilValueLoadable(
    GetAssignableUsersForSpecificRotaDuty({
      taskId: rotaDetails.taskId,
      parishIds: filterParishIds,
    })
  );
  const users: SlimUser[] =
    usersLoadable.state === 'hasValue' ? usersLoadable.contents : [];
  const usersIndexedLoadable = useRecoilValueLoadable(OrganizationUsersIndexed);
  const usersIndexed =
    usersIndexedLoadable.state === 'hasValue'
      ? usersIndexedLoadable.contents
      : [];
  const churches = useRecoilValue(OrganizationChurches);
  const [userAssignedIds, setUserAssignedIds] = useState(
    rotaDetails?.users?.map((user) => user.id) || []
  );
  const userAssignedList = orderUsers(
    userAssignedIds.reduce((accumulator, userId) => {
      usersIndexed[userId] && accumulator.push(usersIndexed[userId]);
      return accumulator;
    }, [])
  ).map((user) => ({
    ...user,
    otherShifts: findShiftsWithUser(otherShifts, user.id),
  }));
  const [keywordToSearchUsersByName, setKeywordToSearchusersByName] =
    useState('');
  const selectorOptionsUsers = users
    .filter((user) => !userAssignedIds?.includes(user.id))
    .map((user) => ({
      ...user,
      otherShifts: findShiftsWithUser(otherShifts, user.id),
    }));

  const busyUsersLoadable = useRecoilValueLoadable(
    CheckUsersAvailablility({
      eventId: rotaDetails.calendarId,
      endDate: endDate.toISOString(),
      startDate: startDate.toISOString(),
      users: userAssignedIds,
      rrule,
      initialRrule,
    })
  );
  const busyUsers =
    busyUsersLoadable.state === 'hasValue' ? busyUsersLoadable.contents : [];

  const loading =
    busyUsersLoadable.state === 'loading' || usersLoadable.state === 'loading';
  const handleChange = (value) => {
    setUserAssignedIds([...userAssignedIds, value]);
  };

  const handleRemoveAssignedUser = (removeUserId) => {
    const unAssignableUser = rotaDetails?.users?.find(
      (u) => u.id === removeUserId && u.canUnassign === false
    );
    if (!unAssignableUser) {
      setUserAssignedIds((prev) =>
        prev.filter((userId) => userId !== removeUserId)
      );
    } else {
      NotificationService.notifyError(
        gettextCatalog.getString('You cannot unassign this user')
      );
    }
  };
  const handleOnOk = useCallback(() => {
    updateRotaTableStateToAddUserToTheRota(
      rotaDetails.calendarId,
      rotaDetails.taskId,
      userAssignedIds,
      form.getFieldValue('note')
    );
  }, [
    form,
    rotaDetails.calendarId,
    rotaDetails.taskId,
    updateRotaTableStateToAddUserToTheRota,
    userAssignedIds,
  ]);

  return {
    modal,
    form,
    userAssignedList,
    userAssignedIds,
    busyUsers,
    loading,
    handleChange,
    handleRemoveAssignedUser,
    handleOnOk,
    keywordToSearchUsersByName,
    setKeywordToSearchusersByName,
    selectorOptionsUsers,
    filterParishIds,
    setFilterParishIds,
    token,
    startDate,
    allDay,
    isMultiParish: churches?.length > 1,
  };
};

export const useAssignUserToRotaSchemeModal = ({
  event,
  taskId,
}: { event: DetailedEvent; taskId: number }) => {
  const { useToken } = theme;
  const { token } = useToken();
  const [form] = Form.useForm();
  const refreshEvent = useRecoilRefresher(
    EventQuery({ calendarId: event.id, clientVersion: 3 })
  );
  const state = StateServiceFactory();
  const rotaDutyDetails = event?.rotas.find((r) => r.taskId === taskId);
  const shift = event?.shiftsAndIntentions
    .find((s) => s.calendarId === event.id)
    ?.shifts.find((s) => s.taskId === taskId);
  const otherShifts = event?.shiftsAndIntentions
    .find((s) => s.calendarId === event.id)
    ?.shifts.filter((shift) => shift.taskId !== taskId)
    .map((shift) => ({
      ...shift,
      name: event?.rotas.find((rota) => rota.taskId === shift.taskId)?.title,
    }));
  const defaultParishes = event?.churches.map((church) => church.id);
  const [filterParishIds, setFilterParishIds] =
    useState<number[]>(defaultParishes);
  const usersLoadable = useRecoilValueLoadable(
    GetAssignableUsersForSpecificRotaDuty({
      taskId,
      parishIds: filterParishIds,
    })
  );
  const users: SlimUser[] =
    usersLoadable.state === 'hasValue' ? usersLoadable.contents : [];
  const usersIndexedLoadable = useRecoilValueLoadable(OrganizationUsersIndexed);
  const usersIndexed =
    usersIndexedLoadable.state === 'hasValue'
      ? usersIndexedLoadable.contents
      : [];
  const organizationChurches = useRecoilValue(OrganizationChurches);
  const [userAssignedIds, setUserAssignedIds] = useState(
    shift?.users?.map((user) => user.id) || []
  );
  const userAssignedList = orderUsers(
    userAssignedIds.reduce((accumulator, userId) => {
      usersIndexed[userId] && accumulator.push(usersIndexed[userId]);
      return accumulator;
    }, [])
  ).map((user) => ({
    ...user,
    otherShifts: findShiftsWithUser(otherShifts, user.id),
  }));
  const [keywordToSearchUsersByName, setKeywordToSearchusersByName] =
    useState('');
  const selectorOptionsUsers = users
    .filter((user) => !userAssignedIds?.includes(user.id))
    .map((user) => ({
      ...user,
      otherShifts: findShiftsWithUser(otherShifts, user.id),
    }));

  const busyUsersLoadable = useRecoilValueLoadable(
    CheckUsersAvailablility({
      eventId: event.id,
      endDate: event?.endDate,
      startDate: event?.startDate,
      users: userAssignedIds,
      rrule: event?.rrule,
      initialRrule: event?.rrule,
    })
  );
  const busyUsers =
    busyUsersLoadable.state === 'hasValue' ? busyUsersLoadable.contents : [];

  const loading =
    busyUsersLoadable.state === 'loading' || usersLoadable.state === 'loading';
  const handleChange = (value) => {
    setUserAssignedIds([...userAssignedIds, value]);
  };

  const handleRemoveAssignedUser = (removeUserId) => {
    const unAssignableUser = shift?.users?.find(
      (u) => u.id === removeUserId && u.access.canUnassign === false
    );
    if (!unAssignableUser) {
      setUserAssignedIds((prev) =>
        prev.filter((userId) => userId !== removeUserId)
      );
    } else {
      NotificationService.notifyError(
        gettextCatalog.getString('You cannot unassign this user')
      );
    }
  };
  const apiSave = async (payload: any, eventId?: number) => {
    const response = await mainApi.put<any>(`/calendar/${eventId}`, payload);
    if (response.status !== 409 && !response.ok) {
      handleError(response);
      throw response;
    }
    return response;
  };

  const handleOnOk = useCallback(
    async (sendNotifications: boolean) => {
      const payload: any = {
        churchIds: event.churches?.map((c) => c.id),
        clientVersion: 3,
        endDate: event.endDate,
        sendNotifications,
        startDate: event.startDate,
        title: event.title,
        type: event.type,
        visibility: event.visibility,
        users: Object.keys(event.users).map((id) => parseInt(id, 10)),
        shiftsAndIntentions: [
          {
            calendarId: event.id,
            shifts: [
              {
                note: form.getFieldValue('note'),
                taskId,
                users: userAssignedIds.map((id) => ({ id })),
              },
            ],
          },
        ],
      };
      let response = await apiSave(payload, event.id);

      if (response.status === 409) {
        // Double booking was detected. Ask user to allow it.
        NotificationService.notifyWarning(
          gettextCatalog.getString(
            'One or more conflicts prevented this from being saved.'
          )
        );
        const allowDoubleBooking = await showConflicts(response.data);
        response = await apiSave({ ...payload, allowDoubleBooking }, event.id);
      }
      if (
        state.current.name === 'app.private.calendar.rotas.view' ||
        state.current.name === 'app.private.calendar.rotas.default'
      ) {
        state.reload();
      }
      refreshEvent();
      return response;
    },
    [event, form, refreshEvent, taskId, userAssignedIds, state]
  );

  return {
    form,
    userAssignedList,
    userAssignedIds,
    busyUsers,
    loading,
    handleChange,
    handleRemoveAssignedUser,
    handleOnOk,
    keywordToSearchUsersByName,
    setKeywordToSearchusersByName,
    selectorOptionsUsers,
    filterParishIds,
    setFilterParishIds,
    token,
    startDate: event?.startDate,
    allDay: event?.allDay,
    isMultiParish: organizationChurches?.length > 1,
    rotaDutyDetails,
    note: shift?.note,
    canEditUsers: true,
  };
};

export interface Shift {
  note?: string;
  taskId: number;
  users?: Array<{ id: number }>;
  access: { canEdit: boolean; canAssignMySelf: boolean };
  name: string;
}

export const findShiftsWithUser = (shifts: Shift[], userId: number) =>
  shifts.filter((shift) =>
    shift.users?.map((user) => user.id).includes(userId)
  );
