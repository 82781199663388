import React, { useEffect, useMemo, useState } from 'react';
import { UseQueryResult, UseSuspenseQueryResult } from '@tanstack/react-query';

import CdTable, { CdTableProps } from '../cd-table';

import { OrderFunctions, useCdClientSideTable } from './useCdClientSideTable';
import { CdClientSideTableApiSearchParam } from './types';

import { ApiSearchResult } from '@/react/api';
interface CdClientSideTableProps<T>
  extends Pick<
    CdTableProps<T>,
    | 'noStyle'
    | 'pageSize'
    | 'columns'
    | 'emptyStateProps'
    | 'actionButtons'
    | 'columnSelectorKey'
    | 'headingText'
    | 'filterForm'
    | 'rowSelection'
    | 'rowStyles'
    | 'rowClassName'
    | 'resetSorting'
    | 'isLoading'
    | 'rowKey'
  > {
  querySource: (
    params?: CdClientSideTableApiSearchParam
  ) =>
    | UseQueryResult<ApiSearchResult<T>>
    | UseSuspenseQueryResult<ApiSearchResult<T>>;
  searchParams?: Record<string, any>;
  orderFunctions?: OrderFunctions<T>;
  filters?: ((item: T, index: number) => boolean)[];
  resetPaginationOnDataChange?: boolean;
}

function CdClientSideTable<T extends Record<string, any> = any>(
  props: CdClientSideTableProps<T>
) {
  const {
    resetPaginationOnDataChange = true,
    resetSorting,
    querySource,
    searchParams,
    filters,
    orderFunctions,
    pageSize,
    rowStyles,
    rowClassName,
    columns,
    ...rest
  } = props;

  const { data, handleTableChange, isLoading, order } = useCdClientSideTable<T>(
    {
      querySource,
      extraData: searchParams,
      orderFunctions,
      defaultPageSize: pageSize,
      filters,
    }
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  useEffect(() => {
    if (resetPaginationOnDataChange) {
      setPageNumber(1);
      handleTableChange(1, pageSize, order);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const actualColumns = useMemo(
    () =>
      columns
        .filter((column) => !!column)
        .map((column) => ({ ...column, sorter: !!column.orderType })),
    [columns]
  );

  return (
    <CdTable<T>
      rowKey="id"
      {...rest}
      columns={actualColumns}
      onTableChange={handleTableChange}
      page={[pageNumber, setPageNumber]}
      data={data}
      isLoading={isLoading}
      pageSize={pageSize}
      rowStyles={rowStyles}
      rowClassName={rowClassName}
      resetSorting
    />
  );
}

export default CdClientSideTable;
