import React, { useEffect } from 'react';

import { createCdDrawer } from '../../shared/components/cd-drawer/CdDrawer';
import { gettextCatalog } from '../../services/I18nService';

import { EditSafeguardingSuspended } from './EditSafeguardingSuspended';
import { SafeguardingForm } from './SafeguardingForm';

export const showSafeguardingDrawer = createCdDrawer<{
  isEdit: boolean;
  currentUserId: number;
  currentUserOrganizationStatus: string;
}>(
  (props) => {
    const { setDrawerProps } = props;
    useEffect(() => {
      setDrawerProps({
        fallback: <EditSafeguardingSuspended />,
        hideCancel: true,
        hideOk: true,
      });
    }, [setDrawerProps]);

    return (
      <SafeguardingForm
        isEdit={props.isEdit}
        currentUserId={props.currentUserId}
        currentUserOrganizationStatus={props.currentUserOrganizationStatus}
        closeDrawer={props.closeDrawer}
      />
    );
  },
  { width: 1000, title: gettextCatalog.getString('Documents and certificates') }
);
