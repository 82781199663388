import { selectorFamily, selector, atom } from 'recoil';
import { Moment } from 'moment';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { CdClientSideTableApiSearchParam } from '../../../shared/components/cd-client-side-table/types';
import { sortString } from '../../../shared/libs/sorter';

import { mainApi } from '@/react/api';
import { DetailedEvent, EventType } from '@/react/calendar/models/calendar';
import { PeopleMessageTemplateFromList } from '@/react/people/types/message-template.type';
import { handleError } from '@/react/services/ErrorHandlingService';
import { OrganizationUsers } from '@/react/organization/store/organization';
import {
  ApiEventRegistrationResult,
  CalendarPoster,
  EventRegistration,
  SogndkParish,
  SogndkCategories,
  SogndkChurches,
  SogndkPriest,
} from '@/react/calendar/types/event';
import cdApp from '@/react/config';
import { GetNotifications } from '@/react/calendar/store/events/eventBookedusersNotifications';
import { getRRuleText } from '@/react/calendar/services/RRuleService';
import { RevisionList } from '@/react/calendar/event-details/types/revisions.type';
import { getCalendar } from '@/react/calendar/event-details/services/event.service';

export const EventQuery = selectorFamily<
  DetailedEvent,
  { calendarId: number; clientVersion?: number }
>({
  key: 'EventQuery',
  get:
    ({ calendarId, clientVersion }) =>
    async () => {
      if (!calendarId) return;
      return getCalendar(calendarId, clientVersion);
    },
});

export const useGetEvent = (
  calendarId: number,
  clientVersion?: number,
  eventType?: EventType
) =>
  useQuery({
    queryKey: ['useGetEvent', { calendarId, clientVersion, eventType }],
    queryFn: async () =>
      await getCalendar(calendarId, clientVersion || 3, eventType),
  });

export const UpdatedPermissionQuery = selectorFamily<
  DetailedEvent,
  {
    eventId: number;
    visibility?: string;
    groups?: number[];
    churches?: { id: number }[];
  }
>({
  key: 'UpdatedPermissionQuery',
  get:
    ({ eventId, visibility, groups, churches }) =>
    async ({ get }) => {
      const event = await get(
        EventQuery({ calendarId: eventId, clientVersion: 3 })
      );

      let _visibility: string;
      if (!visibility && eventId) {
        _visibility = event.visibility;
      } else {
        _visibility =
          visibility === 'internal-group' || visibility === 'internal-all'
            ? 'internal'
            : visibility;
      }

      let _churches: { id: number }[];
      if (!_churches && eventId) {
        _churches = event.churches.map((c) => ({ id: c.id }));
      } else {
        _churches = churches;
      }
      let _groups: { id: number }[];
      if (groups && groups.length > 0) {
        _groups = groups.map((id) => ({ id }));
      } else if (!eventId) {
        _groups = cdApp.organization.myGroups.map((g) => ({ id: g }));
      }

      const payload = {
        visibility: _visibility,
        churches: _churches,
        groups: _groups,
        type: 'event',
        id: eventId,
      };

      const response = await mainApi.post<DetailedEvent>(
        `/calendar/permissions`,
        payload
      );
      if (!response.ok) {
        handleError(response);
        return null;
      }
      // Permission fix until backend correctly handles the showInSlideshow field.
      response.data.fields.showInSlideshow = response.data.fields.facebook;

      return { canDelete: Boolean(event?.canDelete), ...response.data };
    },
});

export const sogndkCategoriesQuery = selector<SogndkCategories[]>({
  key: 'sogndkCategoriesQuery',
  get: async () => {
    const response = await mainApi.get<SogndkCategories[]>(`sogndk/categories`);
    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});
export const sogndkParishQuery = selector<SogndkParish[]>({
  key: 'sogndkParishQuery',
  get: async () => {
    const response = await mainApi.get<SogndkParish[]>(`sogndk`);
    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});
export const sogndkChurchesQuery = selector<SogndkChurches[]>({
  key: 'sogndkChurchesQuery',
  get: async () => {
    const response = await mainApi.get<SogndkChurches[]>(`sogndk/churches`);
    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});
export const sogndkPriestsQuery = selector<SogndkPriest[]>({
  key: 'sogndkPriestsQuery',
  get: async () => {
    const response = await mainApi.get<SogndkPriest[]>(`sogndk/priests`);
    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});

export const PosterQuery = selectorFamily<
  CalendarPoster,
  { calendarId: number; posterId: number }
>({
  key: 'PosterQuery',
  get:
    ({ calendarId, posterId }) =>
    async () => {
      const response = await mainApi.get<CalendarPoster>(
        `/calendar/${calendarId}/poster/${posterId}`
      );

      if (!response.ok) {
        handleError(response);
        return null;
      }
      return response.data;
    },
});

export const CalendarPosterTemplatesQuery = selector<
  PeopleMessageTemplateFromList[]
>({
  key: 'CalendarPosterTemplatesQuery',
  get: async () => {
    const response = await mainApi.get<PeopleMessageTemplateFromList[]>(
      `/v2/people/message-templates?type=calendar-poster`
    );
    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});

export const EventInvitationTemplatesQuery = selector<
  PeopleMessageTemplateFromList[]
>({
  key: 'EventInvitationTemplatesQuery',
  get: async () => {
    const response = await mainApi.get<PeopleMessageTemplateFromList[]>(
      `/v2/people/message-templates?type=event-invitation`
    );
    if (!response.ok) {
      handleError(response);
      return null;
    }
    return response.data;
  },
});

export const CheckUsersAvailablility = selectorFamily<
  number[],
  CheckUsersAvailablilityInputs
>({
  key: 'CheckUsersAvailablility',
  get: (body: CheckUsersAvailablilityInputs) => async () => {
    if (
      (body.users || []).length === 0 &&
      (body.resources || []).length === 0
    ) {
      return [];
    }
    // the rrule shouldn't be considered if it is an existing event or the rrule hasn't changed
    if (body.initialRrule === body.rrule) {
      body.rrule = undefined;
    }
    body.initialRrule = undefined;
    const response = await mainApi.post<any>('calendar/check', body);
    if (response.ok) {
      return [];
    } else {
      const usersId = Object.entries(response.data.users).map(
        (users) => +users[0]
      );
      return usersId;
    }
  },
});

export const useEventRegistrations = (
  params: CdClientSideTableApiSearchParam
) =>
  useSuspenseQuery({
    queryKey: ['useEventRegistrations', params.extraData.calendarId],
    queryFn: async () => {
      const defaultResponse: ApiEventRegistrationResult = {
        hasMoreThanOnePeopleSection: false,
        hasTickets: true,
        items: [],
        total: 0,
      };

      if (!params.extraData.calendarId) {
        return defaultResponse;
      }

      const response = await mainApi.get<{
        hasMoreThanOnePeopleSection: boolean;
        hasTickets: boolean;
        items: EventRegistration[];
      }>(`/calendar/${params.extraData.calendarId}/registrations?version=2`);

      if (!response.ok) {
        handleError(response);
        return defaultResponse;
      }
      return {
        // An 'id' is needed for the CdTable to ensure that a key is set per row.
        hasMoreThanOnePeopleSection: response.data.hasMoreThanOnePeopleSection,
        hasTickets: response.data.hasTickets,
        items: response.data.items
          ?.map((item) => ({
            ...item,
            id: item.personId,
          }))
          .sort((a, b) => sortString(a.firstName, b.firstName)),
        total: response.data.items?.length,
      };
    },
  });

export const SignUpFormSearch = atom<string>({
  key: 'SignUpFormSearch',
  default: '',
});

export const BookedUsersWithAttendingAndNotifications = selectorFamily<
  BookedUser[],
  { users: number[]; eventId: number }
>({
  key: 'EventShowUsers',
  get:
    ({ users, eventId }) =>
    ({ get }) => {
      const allUsers = get(OrganizationUsers);
      const alreadyBookedUsers = get(
        EventQuery({ calendarId: eventId, clientVersion: 3 })
      )?.users;
      const notifications = get(
        GetNotifications({ entityId: eventId, entityType: 'calendar' })
      );
      const showUsers = allUsers
        .filter((user) => users?.includes(user.id))
        .map((user) => {
          const matchUser = alreadyBookedUsers
            ? alreadyBookedUsers[user.id]
            : null;
          return {
            ...user,
            attending: matchUser?.attending,
            createdAt: matchUser?.createdAt,
            notifications: notifications
              .filter((notif) => notif.userId === user.id)
              .map((notif) => ({
                smsLogs: notif.smsLogs,
                emailLogs: notif.emailLogs,
                notifiedAt: notif.notifiedAt,
              })),
          };
        });

      return showUsers;
    },
});

export type BookedUser = {
  id: number;
  name: string;
  email: string;
  picture: string;
  createdAt: Date;
  attending: string;
  notifications: {
    smsLogs: [];
    emailLogs: [];
    notifiedAt: string;
  }[];
};

export const CdRRuleSummaryState = selectorFamily<
  string,
  { rrule: string; startDate: string | Date | Moment }
>({
  key: 'CdRRuleSummaryState',
  get: (props) => async () => getRRuleText(props),
});

export type CheckUsersAvailablilityInputs = {
  endDate: string | Date;
  eventId: number;
  resources?: number[];
  startDate: string | Date;
  users?: number[];
  rrule: string;
  initialRrule: string;
};

export const EventHistory = selectorFamily<
  { items: RevisionList[]; total: number },
  { eventId: number }
>({
  key: 'EventHistory',
  get:
    (props) =>
    async ({ get }) => {
      // Busts cache when event is refreshed.
      await get(EventQuery({ calendarId: props.eventId, clientVersion: 3 }));

      const response = await mainApi.get<RevisionList[]>(
        `/revisions/event/${props.eventId}`
      );
      if (!response.ok) {
        handleError(response);
        return { items: [], total: 0 };
      }
      const sortedRevisions = response.data.sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      );
      sortedRevisions[0].isCurrentVersion = true;
      return { items: sortedRevisions, total: response.data.length };
    },
});

export const EventInitializationBaseOnFormFields = atom<any>({
  key: 'EventInitializationBaseOnFormFields',
  default: null,
});

export interface EventPostPayload {
  startDate: string;
  endDate: string;
  allDay?: boolean;
  rrule?: string;
  type: string;
  visibility?: string;
  title?: string;
  resources?: number[];
  mainCategory: number;
  groupIds?: number[];
  locationName?: string;
  locationObj?: {
    address: string;
    address2?: string;
    city: string;
    country: string;
    state?: string;
    zipcode: string;
    custom_data?: { resourceId: number };
  };
  users: number[];
  churchIds?: number[];
  timezone?: string;
  clientVersion?: number;
  hideEndTime?: boolean;
  allowDoubleBooking?: boolean;
  bookingOptionIds?: string[];
}

export const postEventAPI = async (payload: EventPostPayload) => {
  const response = await mainApi.post<DetailedEvent>(`/calendar`, payload);
  if (response.ok) return response;
  throw response;
};
