import { Button, Form, Input, Radio, Space, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';

import { CdTooltip } from '../../shared/components/cd-tooltip/CdTooltip';

import { AvailabilityTaxonomyFormData } from './types/availability-taxonomy.types';
import { checkForDuplicateTaxonomyAlias } from './store/useAliasValidation';
import { GroupSelect } from './components/GroupSelect';

import { CdEventCategorySelect } from '@/react/calendar/event-details/components/CdEventCategorySelect';
import { ClientEventVisibility } from '@/react/calendar/types/event';
import cdApp from '@/react/config';
import gettextCatalog from '@/react/services/I18nService';
import { getStateUrl } from '@/react/services/StateServiceFactory';
import { VisibilitySelect } from '@/react/settings/booking-pages/components/VisibilitySelect';
import { aliasValidator } from '@/react/settings/booking-pages/validation';
import {
  debouncedValidation,
  maxLength,
  required,
} from '@/react/shared/antd/validations/common';
import { CdParishSelect } from '@/react/shared/components/cd-perish-select/CdParishSelect';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';
import { BookingFormData } from '@/react/shared/models/taxonomy';

// AntD in the current version that we are using does not allow to mix vertical and horizontal form items
// so this is an override to make it possible
const HorizontalFormItem = styled(Form.Item)`
    margin-top: 24px;
    &&& > * {
        flex-direction: row;
        flex-wrap: nowrap;

        & > *:first-child {
            flex: 0 0 150px;
        }

        & > *:last-child {
            flex: 1 1 0;
        }
    }
`;

type DetailsFormProps = {
  formData?: BookingFormData;
  initialValues?: any;
};

export const DetailsForm = ({ formData, initialValues }: DetailsFormProps) => {
  const form = Form.useFormInstance<AvailabilityTaxonomyFormData>();
  const availabilityType = Form.useWatch('availabilityType', form);
  const visibility = Form.useWatch('visibility', form);
  const alias = Form.useWatch('alias', form);

  const showChurchSelector = window?.cdApp?.showChurchSelector;

  return (
    <>
      <CdVerticalSpace>
        <Typography.Title level={3}>
          {gettextCatalog.getString('General settings')}
        </Typography.Title>

        <Typography.Text type="secondary">
          {gettextCatalog.getString(
            'Available times can be requested through a public booking page where users can choose from available time slots.'
          )}
        </Typography.Text>

        <Form.Item
          name="name"
          label={gettextCatalog.getString('Enquiry type e.g. funeral')}
          rules={[required(), maxLength(255)]}
        >
          <Input placeholder={gettextCatalog.getString('E.g. funeral...')} />
        </Form.Item>
      </CdVerticalSpace>
      <Form.Item
        name="availabilityType"
        label={gettextCatalog.getString('Availability for the booking page')}
      >
        <Radio.Group>
          <Radio value="specific-availability">
            {gettextCatalog.getString(
              'Specific availability (Create specific time slots for availability in the calendar)'
            )}
          </Radio>
          <Radio value="general-availability" disabled>
            {gettextCatalog.getString(
              'General availability (Create a general weekly schedule for availability)'
            )}
          </Radio>
        </Radio.Group>
      </Form.Item>
      {(!availabilityType || availabilityType === 'specific-availability') && (
        <Form.Item
          name={['groups']}
          label={gettextCatalog.getString(
            'Which groups can see and make specific availabilities for the booking page?'
          )}
          extra={gettextCatalog.getString(
            'These users are allowed to create and see availabilities.'
          )}
          rules={[required()]}
        >
          <GroupSelect />
        </Form.Item>
      )}
      <Typography.Title level={3}>
        {gettextCatalog.getString('Booking page information')}
      </Typography.Title>
      <Form.Item
        name="bookingName"
        label={gettextCatalog.getString('Public name')}
        rules={[required(), maxLength(255)]}
      >
        <Input placeholder={gettextCatalog.getString('Public name...')} />
      </Form.Item>
      <Form.Item
        name="bookingDescription"
        label={gettextCatalog.getString('Description')}
        rules={[maxLength(3000)]}
      >
        <Input.TextArea
          rows={4}
          placeholder={gettextCatalog.getString('Add a description...')}
        />
      </Form.Item>
      <Form.Item
        name="alias"
        label={gettextCatalog.getString('URL of the booking page')}
        rules={[
          aliasValidator,
          required(),
          initialValues.alias !== alias &&
            debouncedValidation(checkForDuplicateTaxonomyAlias, 500),
        ]}
      >
        <Input
          addonBefore={`${cdApp.references.bookingPage}${cdApp.organization.publicId}/`}
          placeholder={gettextCatalog.getString('alias')}
        />
      </Form.Item>
      <Typography.Title level={4}>
        {gettextCatalog.getString('Enquiry Form for the booking page')}
      </Typography.Title>
      <Form.Item>
        <CdTooltip
          title={
            !formData &&
            gettextCatalog.getString(
              'A form will be generated once the booking page is saved.'
            )
          }
        >
          <Space>
            <Button
              href={getStateUrl('app.private.forms.view', formData)}
              target="_blank"
              disabled={!formData}
            >
              {gettextCatalog.getString('Edit form')}
            </Button>
            <Button
              href={`${cdApp.references.formViewer}/f/${formData?.alias}`}
              disabled={!formData}
              target="_blank"
            >
              {gettextCatalog.getString('View')}
            </Button>
          </Space>
        </CdTooltip>
      </Form.Item>
      <Typography.Title level={4}>
        {gettextCatalog.getString(
          'Event settings for confirmed booking enquiries'
        )}
      </Typography.Title>
      <Typography.Text>
        {gettextCatalog.getString(
          'Confirmed requests will create the following event:'
        )}
      </Typography.Text>
      {showChurchSelector && (
        <HorizontalFormItem
          name="churchIds"
          label={gettextCatalog.getString('Parish')}
          rules={[required()]}
        >
          <CdParishSelect style={{ width: '100%' }} />
        </HorizontalFormItem>
      )}
      <HorizontalFormItem
        name="categoryId"
        label={gettextCatalog.getString('Event category')}
        rules={[required()]}
      >
        <CdEventCategorySelect
          placeholder={gettextCatalog.getString('Add main category')}
        />
      </HorizontalFormItem>
      <HorizontalFormItem
        name="visibility"
        label={gettextCatalog.getString('Visibility')}
        rules={[required()]}
      >
        <VisibilitySelect />
      </HorizontalFormItem>
      {visibility === ClientEventVisibility.INTERNAL_GROUPS && (
        <HorizontalFormItem
          name={['visibilityGroups']}
          label={gettextCatalog.getString('Groups')}
          rules={[required()]}
        >
          <GroupSelect aria-label="visibilityGroups" />
        </HorizontalFormItem>
      )}
    </>
  );
};
