import { Button, ButtonProps } from 'antd';
import React, { useState } from 'react';

export const CdButton = (props: ButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Button
      loading={isLoading}
      {...props}
      onClick={async (e) => {
        try {
          setIsLoading(true);
          await props.onClick?.(e);
        } finally {
          setIsLoading(false);
        }
      }}
    />
  );
};
