import { Avatar, Space, Typography } from 'antd';
import { AvatarSize } from 'antd/es/avatar/AvatarContext';
import { BaseType } from 'antd/es/typography/Base';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { theme } from 'antd';

import { GetOrganizationUser } from '@/react/organization/store/organization';
import { gettextCatalog } from '@/react/services/I18nService';
import {
  CdUserAvatar as CdUserAvatarIcon,
  EventIcons,
} from '@/react/shared/components/Icons';

export const CdUserAvatar = (props: {
  light?: boolean;
  picture?: string;
  name?: string;
  size?: AvatarSize | number;
  blocked?: boolean;
  noIcon?: boolean;
  onClick?: () => void;
  className?: string;
  customIcon?: React.ReactNode;
  style?: React.CSSProperties;
  genericAvatar?: boolean;
}) => {
  const avatarSize: AvatarSize = props.size || 'default';
  const { useToken } = theme;
  const { token } = useToken();
  const typographyOptions = {
    type: 'danger' as BaseType,
  };
  return props.genericAvatar ? (
    <Avatar
      icon={props.customIcon}
      className={props.className}
      onClick={props.onClick}
      size={props.size}
      style={props.style}
      src={props.picture}
    />
  ) : (
    <Space>
      {!props.noIcon && (
        <Avatar
          icon={
            (props.light ? (
              <EventIcons.UserBooking
                style={{
                  color: 'lightgray',
                  width: avatarSize === 'default' ? '30px' : '24px',
                  height: avatarSize === 'default' ? '30px' : '24px',
                }}
              />
            ) : (
              <CdUserAvatarIcon />
            )) || undefined
          }
          src={props.picture || undefined}
          size={avatarSize}
          style={{ backgroundColor: props.light ? 'white' : 'lightgray' }}
        />
      )}
      <Typography.Text {...(props.blocked && typographyOptions)} ellipsis>
        {props.name}
      </Typography.Text>
      {props.blocked && (
        <span style={{ color: props.blocked ? token.colorError : 'auto' }}>
          ({gettextCatalog.getString('Blocked')})
        </span>
      )}
    </Space>
  );
};

export const CdUserAvatarWithState = (props: {
  light?: boolean;
  userId: number;
  size?: AvatarSize;
  blocked?: boolean;
  key?: string | number;
}) => {
  const user = useRecoilValue(GetOrganizationUser({ id: props.userId }));
  return (
    <CdUserAvatar
      light={props.light}
      size={props.size}
      name={user?.name}
      picture={user?.picture}
      blocked={user.status === 'blocked'}
      key={props.key}
    />
  );
};
