import { Button, Card, Input, Menu, Alert } from 'antd';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Provider } from 'react-redux';
import styled from 'styled-components';
import { get, isEmpty, map } from 'lodash';

import Store from '../../redux';
import { gettextCatalog } from '../../services/I18nService';
import FormService from '../services/FormService';
import { ChurchdeskForm } from '../models/forms';
import CdrEmptyState from '../../shared/components/cd-result/CdrEmptyState';
import StateServiceFactory, {
  navigate,
} from '../../services/StateServiceFactory';
import { handleSuccessMessage } from '../../shared/utils';
import ErrorHandlingService from '../../services/ErrorHandlingService';
import { CdForm, CdClose, CdAngleRight } from '../../shared/components/Icons';

const { Search } = Input;

interface AddFormToEventModalProps {
  modalInstance?: any;
  calendarId: number;
}

const StyledCard = styled(Card)`
  &&&& {
    min-width: 800px;

    .ant-card-body {
      padding: 0;

      .addFormModal {
        display: flex;
        min-height: 600px;

        .addFormModal__sidebar {
          .addFormModal__formsList {
            border-right: 0;
          }
        }

        .addFormModal__formPreview {
          flex: 3;
          display: flex;

          .addFormModal__formPreview__card {
            flex: 1;
            display: flex;

            .ant-card-body {
              flex: 1;
              display: flex;
              flex-direction: column;
              padding: 0;

              .addFormModal__formPreview__card__iFrame {
                width: -webkit-fill-available;
                border: none;
                flex: 1;
                padding: 0 16px;
              }
            }
          }
        }
      }
    }
    .ant-card-actions {
      height: 60px;
    }
  }
`;

const AddFormToEventModal: FunctionComponent<AddFormToEventModalProps> = ({
  modalInstance,
  calendarId,
}) => {
  const [availableForms, updateAvailableForms] = useState<ChurchdeskForm[]>([]);
  const [selectedForm, updateSelectedForm] = useState<ChurchdeskForm>();
  const [submitting, updateSubmitting] = useState<boolean>(false);
  const stateService = StateServiceFactory();

  const _searchForms = useCallback(
    (title) => {
      FormService.searchForms(title)
        .then((forms) => {
          if (isEmpty(forms)) {
            updateSelectedForm(null);
          } else if (!selectedForm) {
            updateSelectedForm(forms[0]);
          }

          updateAvailableForms(forms);
        })
        .catch(ErrorHandlingService.handleError);
    },
    [selectedForm]
  );

  // Initial load
  useEffect(() => {
    // Search forms with no title to get all
    _searchForms(null);
  }, [_searchForms]);

  const closeModal = () => {
    modalInstance.dismiss();
  };

  const onSearch = (searchText) => {
    _searchForms(searchText);
  };

  const addFormToEvent = (form) => {
    updateSubmitting(true);
    if (form.calendarId) {
      FormService.copyForm(form)
        .then((copiedForm) =>
          FormService.addFormToEvent(calendarId, copiedForm.id)
        )
        .then(() => {
          handleSuccessMessage(
            gettextCatalog.getString('Successfully copied and added form.')
          );
          modalInstance.dismiss();
          navigate(
            'app.private.calendar.event',
            { id: calendarId, openAddForm: false },
            { reload: true }
          );
          updateSubmitting(false);
        })
        .catch((e) => {
          updateSubmitting(false);
          ErrorHandlingService.handleError(e);
        });
    } else {
      FormService.addFormToEvent(calendarId, form.id)
        .then(() => {
          handleSuccessMessage(
            gettextCatalog.getString('Successfully added form.')
          );
          modalInstance.dismiss();
          navigate(
            'app.private.calendar.event',
            { id: calendarId, openAddForm: false },
            { reload: true }
          );
          updateSubmitting(false);
        })
        .catch((e) => {
          updateSubmitting(false);
          ErrorHandlingService.handleError(e);
        });
    }
  };

  const navigateToForms = () => {
    navigate('app.private.forms.default');
  };

  return (
    <StyledCard
      title={gettextCatalog.getString('Add a sign up form to your event')}
      bordered={false}
      extra={
        <Button
          type="text"
          shape="circle"
          icon={<CdClose onClick={() => closeModal()} />}
        />
      }
      actions={[
        <Button
          type="primary"
          style={{ position: 'absolute', right: 20 }}
          onClick={() => addFormToEvent(selectedForm)}
          disabled={submitting}
        >
          {' '}
          {gettextCatalog.getString('Add to event')}
        </Button>,
      ]}
    >
      {/* Form search and preview */}
      <div className="addFormModal">
        <div className="addFormModal__sidebar">
          <Search
            placeholder={gettextCatalog.getString('Search form titles...')}
            allowClear
            onSearch={(searchText) => onSearch(searchText)}
            style={{ width: 230, margin: '10px', padding: '0 11px' }}
          />
          <Menu
            selectedKeys={selectedForm ? [selectedForm.id] : null}
            className="addFormModal__formsList"
            mode="inline"
            style={{ maxWidth: 250, maxHeight: '70vh', overflowY: 'scroll' }}
          >
            {map(availableForms, (form) => (
              <Menu.Item
                key={form.id}
                onClick={() => updateSelectedForm(form)}
                className={
                  form.id === get(selectedForm, 'id') ? 'previewedForm' : null
                }
                style={{
                  height: 'fit-content',
                  lineHeight: 1.6,
                  whiteSpace: 'break-spaces',
                  marginTop: 0,
                  marginBottom: 8,
                  padding: '8px 0 8px 16px',
                }}
              >
                <span
                  style={{
                    display: 'block',
                    overflow: 'hidden',
                    width: '100%',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {form.title}
                </span>
              </Menu.Item>
            ))}
          </Menu>
        </div>
        <div className="addFormModal__formPreview">
          {selectedForm ? (
            // Preview form and give actions buttons
            <Card className="addFormModal__formPreview__card">
              {selectedForm.calendarId ? (
                <Alert
                  banner
                  type="info"
                  showIcon
                  message={
                    <div>
                      {gettextCatalog.getString(
                        "Form is already in use. But you can still use it. We'll simply make a copy of the form when you add it to the event."
                      )}{' '}
                      <a
                        style={{ float: 'right' }}
                        href={stateService.href(`app.private.calendar.event`, {
                          id: selectedForm.calendarId,
                          openAddForm: false,
                        })}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {gettextCatalog.getString('View event')}{' '}
                        <CdAngleRight />
                      </a>
                    </div>
                  }
                />
              ) : null}
              <iframe
                className="addFormModal__formPreview__card__iFrame"
                src={selectedForm.url + '/embed'}
              ></iframe>
            </Card>
          ) : (
            <div style={{ flex: 3 }}>
              <CdrEmptyState
                title={gettextCatalog.getString('No forms found')}
                subtitle={gettextCatalog.getString(
                  'You need to first create a form to add it to an event'
                )}
                buttonText={gettextCatalog.getString('Go to forms')}
                EmptyStateIcon={<CdForm />}
                onButtonClick={navigateToForms}
              />
            </div>
          )}
        </div>
      </div>
    </StyledCard>
  );
};

const AddFormToEventModalRoot: FunctionComponent<{ modalInstance; resolve }> =
  ({ modalInstance, resolve }) => {
    const store = Store.getStore();
    return (
      <Provider store={store}>
        <AddFormToEventModal
          modalInstance={modalInstance}
          calendarId={resolve.calendarId}
        />
      </Provider>
    );
  };
export default AddFormToEventModalRoot;
